/* eslint-disable react/jsx-no-target-blank */
import { useEffect, useMemo } from "react";
import { useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";
import { isSuperAdmin } from "../../../../../hooks/useUserInfo";
import { setData } from "../../../../../store/slice/header/headerSlice";
import { SidebarMenuItem } from "./SidebarMenuItem";
import { SidebarMenuItemWithSub } from "./SidebarMenuItemWithSub";

const SidebarMenuMain = () => {

  const location = useLocation();
  const dispatch = useDispatch();
  const pathname = location?.pathname;

  interface ISideBarChildMenu {
    path: string;
    title: string;
    child: [];
    hasBullet: boolean;
  }
  interface ISideBarMenu {
    path: string;
    title: string;
    icon: string;
    fontIcon: string;
    child: ISideBarChildMenu[] | [];
    hasBullet: boolean;
    isSuperAdmin: boolean;
    isAdmin: boolean;
  }
  const sideBarMenus: ISideBarMenu[] = [
    {
      path: "/dashboard",
      icon: "dashboard",
      title: "Dashboard",
      fontIcon: "bi-layers",
      child: [],
      hasBullet: false,
      isSuperAdmin,
      isAdmin: true,
    },
    {
      path: "/banner",
      icon: "banner",
      title: "Banner",
      fontIcon: "bi-layers",
      child: [],
      hasBullet: false,
      isSuperAdmin,
      isAdmin: false,
    },
    {
      path: "/golf-club",
      icon: "abstract-3",
      title: "Golf Club",
      fontIcon: "bi-layers",
      child: [],
      hasBullet: false,
      isSuperAdmin,
      isAdmin: true,
    },
    {
      path: "/squezz-me",
      icon: "squeez",
      title: "Squeez",
      fontIcon: "bi-layers",
      child: [],
      hasBullet: false,
      isSuperAdmin,
      isAdmin: true,
    },
    
    {
      path: "/waiting",
      icon: "waiting",
      title: "WaitList",
      fontIcon: "bi-layers",
      child: [],
      hasBullet: false,
      isSuperAdmin,
      isAdmin: true,
    },
    
    {
      path: "/golfAdmin",
      icon: "user",
      title: "Golf Admin",
      fontIcon: "bi-layers",
      child: [],
      hasBullet: false,
      isSuperAdmin : false,
      isAdmin: true,

    },
    
    {
      path: "/company",
      icon: "abstract-32",
      title: "Company",
      fontIcon: "bi-layers",
      child: [],
      hasBullet: false,
      isSuperAdmin,
      isAdmin: false,
    },
    // {
    //   path: "/coupon",
    //   icon: "note",
    //   title: "Coupon",
    //   fontIcon: "bi-layers",
    //   child: [],
    //   hasBullet: false,
    //   isSuperAdmin,
    //   isAdmin: false,
    // },
    {
      path: "/membership",
      icon: "subscription",
      title: "Subscription / Membership",
      fontIcon: "bi-layers",
      child: [],
      hasBullet: false,
      isSuperAdmin,
      isAdmin: false,
    },
    {
      path: "/booking",
      icon: "bookmark",
      title: "Tee Time Bookings",
      fontIcon: "bi-layers",
      child: [],
      hasBullet: false,
      isSuperAdmin,
      isAdmin: false,
    },
    {
      path: "/subscription-info",
      icon: "information",
      title: "Subscription Info",
      fontIcon: "bi-layers",
      child: [],
      hasBullet: false,
      isSuperAdmin,
      isAdmin: false,
    },
    {
      path: "/app-user",
      icon: "user",
      title: "App User",
      fontIcon: "bi-layers",
      child: [
        {
          path: "/app-user/app-admin",
          title: "App Admin",
          child: [],
          hasBullet: true,
        },
        {
          path: "/app-user/app-customer",
          title: "App Customer",
          child: [],
          hasBullet: true,
        },
      ],
      hasBullet: false,
      isSuperAdmin,
      isAdmin: false,
    },
    {
      path: "/preference",
      icon: "notification-on",
      title: "User Preference",
      fontIcon: "bi-layers",
      child: [],
      hasBullet: false,
      isSuperAdmin,
      isAdmin: false,
    },
    {
      path: "/terms-conditions",
      icon: "termsConditions",
      title: "Terms & Conditions",
      fontIcon: "bi-app-indicator",
      child: [],
      hasBullet: false,
      isSuperAdmin,
      isAdmin: false,
    },
    {
      path: "/privacy-policy",
      icon: "privacy-policy",
      title: "Privacy Policy",
      fontIcon: "bi-app-indicator",
      child: [],
      hasBullet: false,
      isSuperAdmin,
      isAdmin: false,
    },
    {
      path: "/payment-terms-conditions",
      icon: "information",
      title: "Payment Terms & Conditions",
      fontIcon: "bi-app-indicator",
      child: [],
      hasBullet: false,
      isSuperAdmin,
      isAdmin: false,
    },
    {
      path: "/payment-privacy",
      icon: "squeez",
      title: "Payment Privacy",
      fontIcon: "bi-app-indicator",
      child: [],
      hasBullet: false,
      isSuperAdmin,
      isAdmin: false,
    },
    {
      path: "/membership-description",
      icon: "membership",
      title: "Membership Description",
      fontIcon: "bi-app-indicator",
      child: [],
      hasBullet: false,
      isSuperAdmin,
      isAdmin: false,
    },
    {
      path: "/about-app",
      icon: "about-app",
      title: "About App",
      fontIcon: "bi-app-indicator",
      child: [],
      hasBullet: false,
      isSuperAdmin,
      isAdmin: false,
    },
    {
      path: "/faq",
      icon: "question-2",
      title: "FAQ",
      fontIcon: "bi-app-indicator",
      child: [],
      hasBullet: false,
      isSuperAdmin,
      isAdmin: false,
    },
    {
      path: "/contactus",
      icon: "disconnect",
      title: "Contact Us",
      fontIcon: "bi-app-indicator",
      child: [],
      hasBullet: false,
      isSuperAdmin,
      isAdmin: false,
    },
  ];

  const currentPathTitle = useMemo(() => {
    let currTitle = '';
    sideBarMenus.forEach(side => {
      if(side?.child?.length) {
        side?.child?.forEach((childSide) => {
          if(childSide.path === pathname) {
            currTitle = childSide.title;
          }
        });
      } else {
        if(side.path === pathname) {
          currTitle = side.title;
        }
      }
    });
    return currTitle;
  },[pathname]);

  useEffect(() => {
    dispatch(setData(currentPathTitle));
  }, [currentPathTitle])

  return (
    <>
      {sideBarMenus.map((menu: ISideBarMenu) => {
        return menu.isSuperAdmin || menu.isAdmin ? (
         !isSuperAdmin || ( isSuperAdmin && menu.title != "Golf Admin") ? (
          <div key={menu.title}>
            {menu.child.length ? (
              <SidebarMenuItemWithSub
                to={menu.path}
                title={menu.title}
                hasBullet={menu.hasBullet}
                fontIcon={menu.fontIcon}
                icon={menu.icon}
              >
                {menu.child.map((menuChild: ISideBarChildMenu, i) => (
                  <SidebarMenuItem
                    key={menu.title + i}
                    to={menuChild.path}
                    title={menuChild.title}
                    hasBullet={menuChild.hasBullet}
                  />
                ))}
              </SidebarMenuItemWithSub>
            ) : (
              <SidebarMenuItem
                to={menu.path}
                title={menu.title}
                hasBullet={menu.hasBullet}
                fontIcon={menu.fontIcon}
                icon={menu.icon}
              />
            )}
          </div>
         ) : null
        ) : null;
      })}
    </>
  );
};

export { SidebarMenuMain };
