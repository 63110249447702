import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import DoDisturbOnIcon from "@mui/icons-material/DoDisturbOn";
import InfoIcon from "@mui/icons-material/Info";
import { Box, Tooltip } from "@mui/material";
// import { GridColDef } from "@mui/x-data-grid";
import { isAfter, isToday, parse } from "date-fns";
import React, { useEffect, useRef, useState } from "react";
import { Card } from "react-bootstrap";
import { useMutation } from "react-query";
import { useNavigate } from "react-router-dom";
import { KTCard } from "../../_metronic/helpers";
import { getSquezzMeList, updateSquezzMeStatus } from "../../api";
import ApproveSquezzMe from "../../components/squezz-me-info/ApproveSquezzMe";
import RejectSquezzMe from "../../components/squezz-me-info/RejectSqueezMe";
import SquezzMeInfoDetails from "../../components/squezz-me-info/SquezzMeInfoDetails";
import MuiTable from "../../components/table/MuiTable";
import { charValidate } from "../../components/utility";
import { formatCurrency, formatDate } from "../../helpers/commonFunctions";
import useDebounce from "../../hooks/useDebounce";

const SquezzMe: React.FC = () => {
  const [showRejectRequestConfirmModal, setshowRejectRequestConfirmModal] =
    useState(false);
  const [showAcceptRequestConfirmModal, setshowAcceptRequestConfirmModal] =
    useState(false);
  const [showDetailsPopup, setShowDetailsPopup] = useState(false);
  const [selectedRow, setSelectedRow] = useState<any>();
  const [tableOptions, setTableOptions] = useState({
    offset: 0,
    limit: 10,
    hasNextPage: true,
    hasPrevPage: true,
    totalRowCount: undefined,
    type: "",
    golfType: "",
    search: "",
  });
  const tableRef = useRef();
  const navigate = useNavigate();
  const debounceSearchValue: string = useDebounce(tableOptions?.search, 500);

  const {
    mutateAsync: getSquezzMedata,
    data: golfData,
    isLoading: isGettingGolfData,
  } = useMutation("squezz-me-list", getSquezzMeList);

  const { mutateAsync: updateStatus, isLoading: isDeletingGolf } = useMutation(
    "update-status",
    updateSquezzMeStatus,
  );

  useEffect(() => {
    getSquezzMe(tableOptions);
  }, []);

  useEffect(() => {
    getSquezzMe({
      ...tableOptions,
      search: debounceSearchValue ? debounceSearchValue : "",
    });
  }, [tableOptions?.type, tableOptions?.golfType, debounceSearchValue]);

  const getSquezzMe = async (
    query:
      | {
          offset: number | string;
          limit: number;
          type: string;
          golfType: string;
          search: string;
        }
      | undefined,
  ) => {
    let response = await getSquezzMedata(query);
    if (response?.status) {
      setTableOptions((prevState: any) => {
        return {
          ...prevState,
          offset: response?.data?.offset,
          limit: response?.data?.limit,
          hasNextPage: response?.data?.hasNextPage,
          hasPrevPage: response?.data?.hasPrevPage,
          totalRowCount: response?.data?.totalDocs,
        };
      });
    }
  };

  const columns: any = [
    {
      field: "name",
      headerName: "Name",
      flex: 1,
      sortable: false,
      headerAlign: "center",
      align: "center",
      minWidth: 110,
      renderCell: (data: any) => (
        <Tooltip
          title={
            data?.row?.userId ? (
              <h6 style={{ color: "white" }}>
                {data?.row?.userId?.firstName +
                  " " +
                  data?.row?.userId?.lastName}
              </h6>
            ) : (
              "N/A"
            )
          }
        >
          <span style={{ whiteSpace: "normal", textAlign: "center" }}>
            {data?.row?.userId
              ? data?.row?.userId?.firstName + " " + data?.row?.userId?.lastName
              : "N/A"}
          </span>
        </Tooltip>
      ),
    },
    {
      field: "golf_cource",
      headerName: "Golf Course",
      flex: 1,
      sortable: false,
      minWidth: 130,
      headerAlign: "center",
      align: "center",
      renderCell: (data: any) => (
        <Tooltip
          title={
            data?.row?.golfId ? (
              <h6 style={{ color: "white" }}>{data?.row?.golfId?.name}</h6>
            ) : (
              "N/A"
            )
          }
        >
          <span style={{ whiteSpace: "normal", textAlign: "center" }}>
            {data?.row?.golfId
              ? charValidate(data?.row?.golfId?.name, 30)
              : "N/A"}
          </span>
        </Tooltip>
      ),
    },
    {
      field: "date",
      headerName: "Desired Date (MM/DD/YY)",
      flex: 1,
      sortable: false,
      headerAlign: "center",
      align: "center",
      minWidth: 135,
      renderCell: (data: any) =>
        data?.row?.slotDate
          ? formatDate(data?.row?.slotDate, "MM/dd/yy")
          : "N/A",
    },
    {
      field: "earliestTime",
      headerName: "Earliest Time",
      sortable: false,
      minWidth: 95,
      headerAlign: "center",
      align: "center",
      renderCell: (data: any) => `${data?.row?.startTime}`,
    },
    {
      field: "latestTime",
      headerName: "Latest Time",
      flex: 1.5,
      sortable: false,
      minWidth: 85,
      headerAlign: "center",
      align: "center",
      renderCell: (data: any) => `${data?.row?.endTime}`,
    },
    // {
    //   field: "description",
    //   headerName: "Description",
    //   renderCell: (data) => (
    //     <Tooltip
    //       title={
    //         data?.row?.description ? <h6>{data?.row?.description}</h6> : "N/A"
    //       }
    //     >
    // <span>
    //   {data?.row?.description
    //     ? charValidate(data?.row?.description, 30)
    //     : "N/A"}
    // </span>
    //     </Tooltip>
    //   ),
    //   flex: 1.3,
    //   sortable: false,
    // },
    {
      field: "player",
      headerName: "Player/s",
      sortable: false,
      flex: 1,
      minWidth: 90,
      headerAlign: "center",
      align: "center",
      renderCell: (data: any) => data?.row?.players ?? "N/A",
    },
    {
      field: "price",
      headerName: "Price/Players",
      sortable: false,
      minWidth: 90,
      headerAlign: "center",
      align: "center",
      renderCell: (data: any) => `${formatCurrency(data?.row?.price)}` ?? "N/A",
    },
    {
      field: "status",
      headerName: "Status",
      sortable: false,
      minWidth: 105,
      // lineBreak: "anywhere",
      // whiteSpace: "none",
      headerAlign: "center",
      align: "center",
      renderCell: (data: any) => (
        <span style={{ whiteSpace: "normal", textAlign: "center" }}>
          {data?.row?.statustext ?? "N/A"}
        </span>
      ),
      // renderCell: (data: any) => data?.row?.statustext ?? "N/A",
    },
    {
      field: "rating",
      headerName: "Rating",
      sortable: false,
      flex: 1.1,
      minWidth: 85,
      headerAlign: "center",
      align: "center",
      renderCell: (data: any) =>
        parseInt(data?.row?.userId?.squeezRating) + "%" ?? "N/A",
    },
    // {
    //   field: "isActive",
    //   headerName: "Status",
    //   flex: 0.7,
    //   sortable: false,
    //   renderCell: (data: any) =>
    //     typeof data?.row?.status !== "undefined" ? (
    //       data?.row?.status ? (
    //         <div className="d-flex align-items-center">
    //           <CheckCircleIcon sx={{ fontSize: 15, mr: 1, color: "green" }} />
    //           <span>Active</span>
    //         </div>
    //       ) : (
    //         <div className="d-flex align-items-center">
    //           <CheckCircleIcon sx={{ fontSize: 15, mr: 1 }} color="disabled" />
    //           <span>Inactive</span>
    //         </div>
    //       )
    //     ) : (
    //       "N/A"
    //     ),
    // },
    {
      field: "actions",
      headerName: "Actions",
      width: 120,
      sortable: false,
      headerAlign: "center",
      align: "center",
      renderCell: (data: any) => (
        <Box display="flex" alignItems="center">
          <Tooltip title="Info">
            <InfoIcon
              onClick={() => {
                setSelectedRow(data.row);
                setShowDetailsPopup(true);
              }}
              sx={{ fontSize: 22, cursor: "pointer", marginRight: 1.5 }}
              color="primary"
            />
          </Tooltip>
          <Tooltip title="Accept">
            <CheckCircleIcon
              onClick={() => {
                if (
                  data?.row?.status == 0 &&
                  dateTimeValidate(formatDate(data?.row?.slotDate, "MM/dd/yy"))
                ) {
                  setSelectedRow(data.row);
                  setshowAcceptRequestConfirmModal(true);
                }
              }}
              sx={{ fontSize: 22, cursor: "pointer" }}
              color={
                data?.row?.status == 0 &&
                dateTimeValidate(formatDate(data?.row?.slotDate, "MM/dd/yy"))
                  ? "success"
                  : "disabled"
              }
            />
          </Tooltip>
          <Tooltip title="Reject">
            <DoDisturbOnIcon
              onClick={() => {
                if (
                  data?.row?.status == 0 &&
                  dateTimeValidate(formatDate(data?.row?.slotDate, "MM/dd/yy"))
                ) {
                  setSelectedRow(data.row);
                  setshowRejectRequestConfirmModal(true);
                }
              }}
              sx={{ fontSize: 22, ml: 1.7, cursor: "pointer" }}
              color={
                data?.row?.status == 0 &&
                dateTimeValidate(formatDate(data?.row?.slotDate, "MM/dd/yy"))
                  ? "error"
                  : "disabled"
              }
            />
          </Tooltip>
        </Box>
      ),
    },
  ];

  const dateTimeValidate = (dateString: string) => {
    if (dateString && typeof dateString !== "undefined") {
      const inputDateTime = parse(dateString, "MM/dd/yy", new Date());
      const todayDate = new Date();
      return isToday(inputDateTime) || isAfter(inputDateTime, todayDate);
    } else {
      return false;
    }
  };

  const handleAcceptClose = () => {
    setshowAcceptRequestConfirmModal(false);
    setSelectedRow("");
    let query = {
      offset: tableOptions?.offset ?? 10,
      limit: tableOptions?.limit ?? 10,
      type: tableOptions?.type ?? "",
      golfType: tableOptions?.golfType ?? "",
      search: debounceSearchValue ?? "",
    };
    getSquezzMe(query);
  };

  const handleAcceptPopup = () => {
    setshowAcceptRequestConfirmModal(false);
    setSelectedRow("");
  };

  const handleRejectClose = () => {
    setshowRejectRequestConfirmModal(false);
    setSelectedRow("");
    let query = {
      offset: tableOptions?.offset ?? 10,
      limit: tableOptions?.limit ?? 10,
      type: tableOptions?.type ?? "",
      golfType: tableOptions?.golfType ?? "",
      search: debounceSearchValue ?? "",
    };
    getSquezzMe(query);
  };

  const handleCellClick = (data: any) => {
    // let golfData: any = data?.row;
    // let fieldName: string = data?.field;
    // if (fieldName !== "actions") {
    //   navigate("/golf-holes", { state: golfData });
    // }
  };

  const handlePagination = (values: { page: number; pageSize: number }) => {
    let page = values?.page;
    let pageSize = values?.pageSize;
    let query = {
      offset:
        pageSize == tableOptions?.limit ? (page === 0 ? 0 : `${page}` + 0) : 0,
      limit: pageSize ?? 10,
      type: tableOptions?.type ?? "",
      golfType: tableOptions?.golfType ?? "",
      search: debounceSearchValue ?? "",
    };
    getSquezzMe(query);
  };

  const handleChangeFilters = (fieldName: string, value: string) => {
    setTableOptions((prevState: any) => {
      return {
        ...prevState,
        [fieldName]: value,
      };
    });
  };

  return (
    <Card className="p-6">
      {/* <PageTitle>Squeez</PageTitle> */}
      <>
        <KTCard>
          <MuiTable
            isAddEnable={false}
            columns={columns}
            data={golfData?.data?.docs ?? []}
            tableRef={tableRef}
            loading={isGettingGolfData}
            isRowClickable={true}
            handleCellClick={handleCellClick}
            handlePagination={handlePagination}
            tableOptions={tableOptions}
            filters={
              <Box pb={3} display="flex" alignItems="center">
                {/* <Box width={0.25}>
                  <label
                    className="form-label fw-bold"
                    style={{ marginLeft: 5 }}
                  >
                    Golf Cources
                  </label>
                  <select
                    onChange={(e: any) =>
                      handleChangeFilters("type", e?.target?.value)
                    }
                    value={tableOptions?.type}
                    className="form-select form-select-solid form-select-lg"
                  >
                    <option value="">Select All</option>
                    <option value="9Holes">9 Holes</option>
                    <option value="18Holes">18 Holes</option>
                  </select>
                </Box> */}
              </Box>
            }
          />
        </KTCard>
        {showDetailsPopup && (
          <SquezzMeInfoDetails
            details={selectedRow}
            show={showDetailsPopup}
            handleClose={() => setShowDetailsPopup(false)}
          />
        )}
        {showAcceptRequestConfirmModal && (
          <ApproveSquezzMe
            details={selectedRow}
            show={showAcceptRequestConfirmModal}
            handleClose={handleAcceptClose}
          />
        )}
        {showRejectRequestConfirmModal && (
          <RejectSquezzMe
            details={selectedRow}
            show={showRejectRequestConfirmModal}
            handleClose={handleRejectClose}
          />
        )}
      </>
    </Card>
  );
};

export default SquezzMe;
