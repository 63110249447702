import { Chip } from "@mui/material";
import { Card, Col, Container, ListGroup, Modal, Row } from "react-bootstrap";
import { createPortal } from "react-dom";
import { KTIcon } from "../../_metronic/helpers";
import { ISquezzMeInfoDetails } from "../../constants/types";
import { formatDateTimeString } from "../../helpers/commonFunctions";
import { charValidate } from "../utility";

type Props = {
  show: boolean;
  handleClose: (isSubmit: boolean) => void;
  details: ISquezzMeInfoDetails;
};

const modalsRoot = document.getElementById("root-modals") || document.body;

const SquezzMeInfoDetails = ({ show, handleClose, details }: Props) => {
  return createPortal(
    <Modal
      id="kt_modal_create_app"
      tabIndex={-1}
      aria-hidden="true"
      dialogClassName="modal-dialog modal-dialog-centered mw-600px"
      show={show}
      onHide={() => handleClose(false)}
      backdrop="static"
    >
      <div className="modal-header">
        <h2>Request Details</h2>
        {/* begin::Close */}
        <div
          className="btn btn-sm btn-icon btn-active-color-primary"
          onClick={() => handleClose(false)}
        >
          <KTIcon className="fs-1" iconName="cross" />
        </div>
        {/* end::Close */}
      </div>

      <div className="p-3" style={{ height: "80vh", overflowY: 'auto' }}>
        <Card style={{ width: "100%", boxShadow: "none" }}>
          <Card.Body>
            <Card.Title className="pb-4">User Details</Card.Title>
            <Container>
              <Row>
                <Col xs={6} style={{ paddingLeft: 0 }}>
                  <Card.Subtitle className="text-muted">
                    Full Name:
                  </Card.Subtitle>
                   <Card.Text>
                      {details?.userId?.firstName || details?.userId?.lastName
                        ? charValidate(
                            details?.userId?.firstName +
                              " " +
                              details?.userId?.lastName,
                            20,
                          )
                        : "N/A"}
                    </Card.Text>
                </Col>
                <Col xs={6}>
                  <Card.Subtitle className="text-muted">Approved By:</Card.Subtitle>
                  <Card.Text>
                      {details?.approvedBy
                        ?  details?.approvedBy?.firstName +
                        " " +
                        details?.approvedBy?.lastName
                        : "N/A"}
                    </Card.Text>
                </Col>
                { details.reasonText && 
                  <Row style={{ paddingLeft: 0,paddingTop: 15 }}>
                  <Card.Subtitle className="text-muted">
                    Reason :
                  </Card.Subtitle>
                  <Card.Text>
                      {details.reasonText}
                    </Card.Text>
                </Row>
                }
                <Col xs={6} style={{ paddingLeft: 0,paddingTop: 15 }}>
                  <Card.Subtitle className="text-muted">
                    Description:
                  </Card.Subtitle>
                  <Card.Text>
                      {details?.description
                        ? details?.description
                        : "N/A"}
                    </Card.Text>
                </Col>
                <Col  xs={6} style={{ paddingLeft: 10,paddingTop: 15 }}>
                  <Card.Subtitle className="text-muted">
                    Created Time:
                  </Card.Subtitle>
                  <Card.Text>
                      {details?.createdAt 
                        ?  formatDateTimeString(details?.createdAt)
                        : "N/A"}
                    </Card.Text>
                </Col>
                <Col xs={6} style={{ paddingLeft: 0,paddingTop: 15 }}>
                  <Card.Subtitle className="text-muted">
                    Approved Time:
                  </Card.Subtitle>
                  <Card.Text>
                      {details?.adminApproveAt   ?
                        formatDateTimeString(details?.adminApproveAt)
                        : "N/A"}
                    </Card.Text>
                </Col>
              </Row>
            
             
            </Container>
            <Card.Title style={{ paddingTop: '1rem', paddingBottom: '1rem' }}>User Preference</Card.Title>
            <Container>
              <Row>

                <Col xs={6} style={{ paddingLeft: 0 }}>
                  <Card.Subtitle className="text-muted">
                    Handicap Score:
                  </Card.Subtitle>
                  <Card.Text>
                    {details?.preference?.handicap ?? "N/A"}
                  </Card.Text>
                </Col>
                <Col xs={6}>
                  <Card.Subtitle className="text-muted">Average Score:</Card.Subtitle>
                  <Card.Text>
                    {details?.preference?.averageScore ?? "N/A"}
                  </Card.Text>
                </Col>
              </Row>
              <Row style={{ paddingTop: 15 }}>
                <Col xs={6} style={{ paddingLeft: 0 }}>
                  <Card.Subtitle className="text-muted">
                    Golf Frequency:
                  </Card.Subtitle>
                  <Card.Text>
                    {details?.preference?.golfFrequency?.name ?? "N/A"}
                  </Card.Text>
                </Col>
                <Col xs={6}>
                  <Card.Subtitle className="text-muted">Time Range:</Card.Subtitle>
                  <Card.Text>
                    {details?.preference?.timeRange?.name ?? "N/A"}
                  </Card.Text>
                </Col>
              </Row>

              <Row style={{ paddingTop: 15 }}>
                <Col xs={6} style={{ paddingLeft: 0 }}>
                  <Card.Subtitle className="text-muted">
                    SkillLevel:
                  </Card.Subtitle>
                  <Card.Text>
                    {details?.preference?.skillLevel?.name ?? "N/A"}
                  </Card.Text>
                </Col>
                <Col xs={6}>
                  <Card.Subtitle className="text-muted">
                    Golf Courses:
                  </Card.Subtitle>
                  <Card.Text>
                    {details?.preference?.golfCourse?.length ? (
                      details?.preference?.golfCourse.map((title: any, index: number) => (
                        <Chip key={index} label={title?.name} style={{ marginTop: '0.6rem', marginRight: '0.6rem' }} />
                      ))
                    ) : (
                      <Card.Text>N/A</Card.Text>
                    )}
                  </Card.Text>
                </Col>
              </Row>

              <Row style={{ paddingTop: 15 }}>
                <Col xs={6} style={{ paddingLeft: 0 }}>
                  <Card.Subtitle className="text-muted">
                    Hobbies:
                  </Card.Subtitle>
                  <Card.Text>
                    {details?.preference?.hobbies ?? "N/A"}
                  </Card.Text>
                </Col>
                <Col xs={6}>
                  <Card.Subtitle className="text-muted">Industry:</Card.Subtitle>
                  <Card.Text>
                    {details?.preference?.industry?.name ?? "N/A"}
                  </Card.Text>
                </Col>
              </Row>
              <Row style={{ paddingTop: 15 }}>
                <Col xs={6} style={{ paddingLeft: 0 }}>
                  <Card.Subtitle className="text-muted">
                    Job Title:
                  </Card.Subtitle>
                  <Card.Text>
                    {details?.preference && details?.preference?.jobTitle
                      ?
                      details?.preference?.jobTitle
                      : "N/A"}
                  </Card.Text>
                </Col>
                <Col xs={6}>
                  <Card.Subtitle className="text-muted">Business Info:</Card.Subtitle>
                  <Card.Text>
                    {details?.preference && details?.preference?.businessInfo
                      ?
                      details?.preference?.businessInfo
                      : "N/A"}
                  </Card.Text>
                </Col>
              </Row>

              <Row style={{ paddingTop: 15 }}>
                <Col xs={6} style={{ paddingLeft: 0 }}>
                  <Card.Subtitle className="text-muted">
                    LinkedIn Profile:
                  </Card.Subtitle>
                  <Card.Text>
                    {details?.preference && details?.preference?.linkedInProfile
                      ?
                      details?.preference?.linkedInProfile
                      : "N/A"}
                  </Card.Text>
                </Col>
                <Col xs={6}>
                  <Card.Subtitle className="text-muted">
                    Buy Stuff on sale or not?
                  </Card.Subtitle>
                  <Card.Text>
                    {details?.preference && details?.preference?.buyStuff
                      ? "Yes"
                      : "No"}
                  </Card.Text>
                </Col>
              </Row>

              <Row style={{ paddingTop: 15 }}>
                <Col xs={6} style={{ paddingLeft: 0 }}>
                  <Card.Subtitle className="text-muted">Music Category:</Card.Subtitle>
                  {details?.preference?.music?.length > 0 ? (
                    details?.preference?.music?.map((title: any, index: number) => (
                      <Chip key={index} label={title?.name ?? "N/A"} style={{ marginTop: '0.6rem', marginRight: '0.6rem' }} />
                    ))
                  ) : (
                    <Card.Text>N/A</Card.Text>
                  )}
                </Col>
                <Col xs={6}>

                  <Card.Subtitle className="text-muted">
                    Payment Style:
                  </Card.Subtitle>
                  {details?.preference?.payment?.length > 0 ? (
                    details?.preference?.payment?.map((title: any, index: number) => (
                      <Chip key={index} label={title?.name ?? "N/A"} style={{ marginTop: '0.6rem', marginRight: '0.6rem' }} />
                    ))
                  ) : (
                    <Card.Text>N/A</Card.Text>
                  )}
                </Col>
              </Row>

              <Row style={{ paddingTop: 15 }}>
                <Col xs={6} style={{ paddingLeft: 0 }}>
                  <Card.Subtitle className="text-muted">
                    Primary Purpose:
                  </Card.Subtitle>
                  {details?.preference?.primaryPurpose?.length > 0 ? (
                    details?.preference?.primaryPurpose?.map((title: any, index: number) => (
                      <Chip key={index} label={title?.name ?? "N/A"} style={{ marginTop: '0.6rem', marginRight: '0.6rem' }} />
                    ))
                  ) : (
                    <Card.Text>N/A</Card.Text>
                  )}
                </Col>
                <Col xs={6}>
                  <Card.Subtitle className="text-muted">
                    Preferences:
                  </Card.Subtitle>
                  {details?.preference?.tolerance?.length > 0 ? (
                    details?.preference?.tolerance?.map((title: any, index: number) => (
                      <Chip key={index} label={title?.name ?? "N/A"} style={{ marginTop: '0.6rem', marginRight: '0.6rem' }} />
                    ))
                  ) : (
                    <Card.Text>N/A</Card.Text>
                  )}
                </Col>
              </Row>

              <Row style={{ paddingTop: 15 }}>
                <Col xs={6} style={{ paddingLeft: 0 }}>
                  <Card.Subtitle className="text-muted">
                    Dislikes:
                  </Card.Subtitle>
                  {details?.preference?.boxes?.length > 0 ? (
                    details?.preference?.boxes?.map((title: any, index: number) => (
                      <Chip key={index} label={title?.name ?? "N/A"} style={{ marginTop: '0.6rem', marginRight: '0.6rem' }} />
                    ))
                  ) : (
                    <Card.Text>N/A</Card.Text>
                  )}
                </Col>
                <Col xs={6}>
                  <Card.Subtitle className="text-muted">
                    Typical Courses:
                  </Card.Subtitle>
                  {details?.preference?.courses?.length > 0 ? (
                    details?.preference?.courses?.map((title: any, index: number) => (
                      <Chip key={index} label={title?.name ?? "N/A"} style={{ marginTop: '0.6rem', marginRight: '0.6rem' }} />
                    ))
                  ) : (
                    <Card.Text>N/A</Card.Text>
                  )}
                </Col>
              </Row>


              <Row style={{ paddingTop: 15 }}>
                <Col xs={6} style={{ paddingLeft: 0 }}>
                  <Card.Subtitle className="text-muted" style={{ marginBottom: '3px', fontSize: '12px' }}>
                    Hats :
                  </Card.Subtitle>
                  {details?.preference?.apparel?.hats?.length > 0 ? (
                    <ListGroup as="ol">
                      {details?.preference?.apparel?.hats?.map((title: any, index: number) => (
                        <ListGroup.Item as="li" key={index} style={{ padding: '3px' }}>
                          <div className="ms-2 me-auto" style={{ fontSize: '10px' }}>
                            Model Name :
                            {title?.name ?? "N/A"}
                          </div>
                          <div className="ms-2 me-auto" style={{ fontSize: '10px' }}>
                            Brand :
                            {title?.brand?.value ?? "N/A"}
                          </div>
                          <div className="ms-2 me-auto" style={{ fontSize: '10px' }}>
                            Size :
                            {title?.size?.value ?? "N/A"}
                          </div>
                          <div className="ms-2 me-auto" style={{ fontSize: '10px' }}>
                            Price :
                            {title?.price ? ` $${title?.price}` : "N/A"}
                          </div>
                        </ListGroup.Item>
                      ))}
                    </ListGroup>
                  ) : (
                    <Card.Text>N/A</Card.Text>
                  )}
                </Col>

                <Col xs={6} >
                  <Card.Subtitle className="text-muted" style={{ marginBottom: '3px', fontSize: '12px' }}>
                    Polos :
                  </Card.Subtitle>
                  {details?.preference?.apparel?.polo?.length > 0 ? (
                    <ListGroup as="ol">
                      {details?.preference?.apparel?.polo?.map((title: any, index: number) => (
                        <ListGroup.Item as="li" key={index} style={{ padding: '3px' }}>
                          <div className="ms-2 me-auto" style={{ fontSize: '10px' }}>
                            Model Name :
                            {title?.name ?? "N/A"}
                          </div>
                          <div className="ms-2 me-auto" style={{ fontSize: '10px' }}>
                            Brand :
                            {title?.brand?.value ?? "N/A"}
                          </div>
                          <div className="ms-2 me-auto" style={{ fontSize: '10px' }}>
                            Size :
                            {title?.size?.value ?? "N/A"}
                          </div>
                          <div className="ms-2 me-auto" style={{ fontSize: '10px' }}>
                            Price :
                            {title?.price ? ` $${title?.price}` : "N/A"}
                          </div>
                        </ListGroup.Item>
                      ))}
                    </ListGroup>
                  ) : (
                    <Card.Text>N/A</Card.Text>
                  )}
                </Col>

              </Row>

              <Row style={{ paddingTop: 15 }}>
                <Col xs={6} style={{ paddingLeft: 0 }}>
                  <Card.Subtitle className="text-muted" style={{ marginBottom: '3px', fontSize: '12px' }}>
                    Jackets/Sweaters :
                  </Card.Subtitle>
                  {details?.preference?.apparel?.cloth?.length > 0 ? (
                    <ListGroup as="ol">
                      {details?.preference?.apparel?.cloth?.map((title: any, index: number) => (
                        <ListGroup.Item as="li" key={index} style={{ padding: '3px' }}>
                          <div className="ms-2 me-auto" style={{ fontSize: '10px' }}>
                            Model Name :
                            {title?.name ?? "N/A"}
                          </div>
                          <div className="ms-2 me-auto" style={{ fontSize: '10px' }}>
                            Brand :
                            {title?.brand.value ?? "N/A"}
                          </div>
                          <div className="ms-2 me-auto" style={{ fontSize: '10px' }}>
                            Size :
                            {title?.size.value ?? "N/A"}
                          </div>
                          <div className="ms-2 me-auto" style={{ fontSize: '10px' }}>
                            Price :
                            {title?.price ? ` $${title?.price}` : "N/A"}
                          </div>
                        </ListGroup.Item>
                      ))}
                    </ListGroup>
                  ) : (
                    <Card.Text>N/A</Card.Text>
                  )}
                </Col>
                <Col xs={6} >
                  <Card.Subtitle className="text-muted" style={{ marginBottom: '3px', fontSize: '12px' }}>
                    Shorts/Pants :
                  </Card.Subtitle>
                  {details?.preference?.apparel?.shorts?.length > 0 ? (
                    <ListGroup as="ol">
                      {details?.preference?.apparel?.shorts?.map((title: any, index: number) => (
                        <ListGroup.Item as="li" key={index} style={{ padding: '3px' }}>
                          <div className="ms-2 me-auto" style={{ fontSize: '10px' }}>
                            Model Name :
                            {title?.name ?? "N/A"}
                          </div>
                          <div className="ms-2 me-auto" style={{ fontSize: '10px' }}>
                            Brand :
                            {title?.brand?.value ?? "N/A"}
                          </div>
                          <div className="ms-2 me-auto" style={{ fontSize: '10px' }}>
                            Size :
                            {title?.size?.value ?? "N/A"}
                          </div>
                          <div className="ms-2 me-auto" style={{ fontSize: '10px' }}>
                            Price :
                            {title?.price ? ` $${title?.price}` : "N/A"}
                          </div>
                        </ListGroup.Item>
                      ))}
                    </ListGroup>
                  ) : (
                    <Card.Text>N/A</Card.Text>
                  )}
                </Col>
              </Row>

              <Row style={{ paddingTop: 15 }}>
                <Col xs={6} style={{ paddingLeft: 0 }}>
                  <Card.Subtitle className="text-muted" style={{ marginBottom: '3px', fontSize: '12px' }}>
                    Shoes :
                  </Card.Subtitle>
                  {details?.preference?.apparel?.shoes?.length > 0 ? (
                    <ListGroup as="ol">
                      {details?.preference?.apparel?.shoes?.map((title: any, index: number) => (
                        <ListGroup.Item as="li" key={index} style={{ padding: '3px' }}>
                          <div className="ms-2 me-auto" style={{ fontSize: '10px' }}>
                            Model Name :
                            {title?.name ?? "N/A"}
                          </div>
                          <div className="ms-2 me-auto" style={{ fontSize: '10px' }}>
                            Brand :
                            {title?.brand?.value ?? "N/A"}
                          </div>
                          <div className="ms-2 me-auto" style={{ fontSize: '10px' }}>
                            Size :
                            {title?.size?.value ?? "N/A"}
                          </div>
                          <div className="ms-2 me-auto" style={{ fontSize: '10px' }}>
                            Price :
                            {title?.price ? ` $${title?.price}` : "N/A"}
                          </div>
                        </ListGroup.Item>
                      ))}
                    </ListGroup>
                  ) : (
                    <Card.Text>N/A</Card.Text>
                  )}
                </Col>

                <Col xs={6} >
                  <Card.Subtitle className="text-muted" style={{ marginBottom: '3px', fontSize: '12px' }}>
                    Golf Clubs :
                  </Card.Subtitle>
                  {details?.preference?.apparel?.golfClub?.length > 0 ? (
                    <ListGroup as="ol">
                      {details?.preference?.apparel?.golfClub?.map((title: any, index: number) => (
                        <ListGroup.Item as="li" key={index} style={{ padding: '3px' }}>
                          <div className="ms-2 me-auto" style={{ fontSize: '10px' }}>
                            Golf Club Type :
                            {title?.size?.name ?? "N/A"}
                          </div>
                          <div className="ms-2 me-auto" style={{ fontSize: '10px' }}>
                            Brand :
                            {title?.brand?.name ?? "N/A"}
                          </div>
                          <div className="ms-2 me-auto" style={{ fontSize: '10px' }}>
                            Model Name :
                            {title?.name ?? "N/A"}
                          </div>

                        </ListGroup.Item>
                      ))}
                    </ListGroup>
                  ) : (
                    <Card.Text>N/A</Card.Text>
                  )}
                </Col>
              </Row>
            </Container>
          </Card.Body>
        </Card>
      </div>


    </Modal>,
    modalsRoot,
  );
};

export default SquezzMeInfoDetails;
