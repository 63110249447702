/* eslint-disable jsx-a11y/anchor-is-valid */
export function Dropdown1() {
  return (
    <div className="menu menu-sub menu-sub-dropdown w-250px w-md-300px" data-kt-menu="true">
      <div className="px-7 py-5">
        <div className="fs-5 text-dark fw-bolder">Golf club</div>
      </div>
      <div className="separator border-gray-200"></div>
      <div className="px-7 py-5">
        <div className="d-flex flex-column">
          <label className="form-check form-check-inline form-check-solid my-2">
            <input className="form-check-input" name="GolfClub" type="radio" checked />
            <span className="fw-bold ps-2 fs-6">Bardmoor GC</span>
          </label>
          
          <label className="form-check form-check-inline form-check-solid my-2">
            <input className="form-check-input" name="GolfClub" type="radio" />
            <span className="fw-bold ps-2 fs-6">TPC</span>
          </label>
          
          <label className="form-check form-check-inline form-check-solid my-2">
            <input className="form-check-input" name="GolfClub" type="radio" />
            <span className="fw-bold ps-2 fs-6">Babe Zaharias</span>
          </label>
          
          <label className="form-check form-check-inline form-check-solid my-2">
            <input className="form-check-input" name="GolfClub" type="radio" />
            <span className="fw-bold ps-2 fs-6">Buffalo Creek</span>
          </label>
          
          <label className="form-check form-check-inline form-check-solid my-2">
            <input className="form-check-input" name="GolfClub" type="radio" />
            <span className="fw-bold ps-2 fs-6">Countryway GC</span>
          </label>
          
          <label className="form-check form-check-inline form-check-solid my-2">
            <input className="form-check-input" name="GolfClub" type="radio" />
            <span className="fw-bold ps-2 fs-6">Manatee GC</span>
          </label>
        </div>
      </div>
    </div>
  );
}
