import { Card, Col, Container, Modal, Row } from "react-bootstrap";
import { createPortal } from "react-dom";
import { KTIcon } from "../../_metronic/helpers";
import { IWaitingInfoDetails } from "../../constants/types";
import { formatDate } from "../../helpers/commonFunctions";
import { charValidate } from "../utility";

type Props = {
  show: boolean;
  handleClose: (isSubmit: boolean) => void;
  details: IWaitingInfoDetails;
};

const modalsRoot = document.getElementById("root-modals") || document.body;

const WaitListInfoDetails = ({ show, handleClose, details }: Props) => {
  return createPortal(
    <Modal
      id="kt_modal_create_app"
      tabIndex={-1}
      aria-hidden="true"
      dialogClassName="modal-dialog modal-dialog-centered mw-600px scroll"
      show={show}
      onHide={() => handleClose(false)}
      backdrop="static"
    >
      <div className="modal-header">
        <h2>Request Details</h2>
        {/* begin::Close */}
        <div
          className="btn btn-sm btn-icon btn-active-color-primary"
          onClick={() => handleClose(false)}
        >
          <KTIcon className="fs-1" iconName="cross" />
        </div>
        {/* end::Close */}
      </div>

      <div className="p-3">
        <Card style={{ width: "100%", boxShadow: "none" , paddingBottom : 0 }}>
          <Card.Body>
            <Card.Title className="pb-4">User Details</Card.Title>
            <Container>
              <Row>
                <Col xs={6} style={{ paddingLeft: 0 }}>
                  <Card.Subtitle className="text-muted">
                    Full Name:
                  </Card.Subtitle>
                   <Card.Text>
                      {details?.user?.firstName || details?.user?.lastName
                        ? charValidate(
                            details?.user?.firstName +
                              " " +
                              details?.user?.lastName,
                            20,
                          )
                        : "N/A"}
                    </Card.Text>
                </Col>
                <Col xs={6}>
                  <Card.Subtitle className="text-muted">Email:</Card.Subtitle>
                  <Card.Text>
                      {details?.user?.email
                        ?  details?.user?.email +
                        " " +
                        details?.user?.email
                        : "N/A"}
                    </Card.Text>
                </Col>
                
                {/* <Col style={{ paddingLeft: 0,paddingTop: 15 }}>
                  <Card.Subtitle className="text-muted">
                    Description:
                  </Card.Subtitle>
                  <Card.Text>
                      {details?.description
                        ? details?.description
                        : "N/A"}
                    </Card.Text>
                </Col> */}
              </Row>
            </Container>
          </Card.Body>
        </Card>
        <Card style={{ width: "100%", boxShadow: "none" , paddingTop: 0 }}>
          <Card.Body>
            <Card.Title className="pb-4">WaitList Details</Card.Title>
            <Container>
              <Row>
                <Col xs={6} style={{ paddingLeft: 0 }}>
                  <Card.Subtitle className="text-muted">Golf Course:</Card.Subtitle>
                  <Card.Text>
                  {details?.golf?.name ? details?.golf?.name : "N/A" }
                    </Card.Text>
                </Col>
                <Col xs={6}>
                  <Card.Subtitle className="text-muted">
                    Date:
                  </Card.Subtitle>
                  <Card.Text>
                     {details.bookingDate  ? formatDate(details?.bookingDate, "MM/dd/yy")  : "N/A"}
                    </Card.Text>
                </Col>
              </Row>

              <Row className="pt-2">
              <Col xs={6} style={{ paddingLeft: 0,paddingTop : 10}}>
                  <Card.Subtitle className="text-muted">
                  Earliest Time:
                  </Card.Subtitle>
                  <Card.Text>
                  {details?.earliestTime
                        ?  details?.earliestTime
                        : "N/A"}
                    </Card.Text>
                </Col>
                <Col xs={6} style={{paddingTop : 10}}>
                  <Card.Subtitle className="text-muted">
                  Latest Time:
                  </Card.Subtitle>
                  <Card.Text>
                  {details?.latestTime
                        ?  details?.latestTime 
                        : "N/A"}
                    </Card.Text>
                </Col>
              </Row>
              <Row className="pt-2">
              <Col xs={6} style={{paddingLeft: 0, paddingTop : 10}}>
                  <Card.Subtitle className="text-muted">
                    Person:
                  </Card.Subtitle>
                  <Card.Text>
                      {details?.person}
                    </Card.Text>
                </Col>
                 <Col  xs={6} style={{paddingTop: 15 }}>
                 <Card.Subtitle className="text-muted">
                    Description:
                  </Card.Subtitle>
                  <Card.Text>
                      {details?.description
                        ? details?.description
                        : "N/A"}
                    </Card.Text>
                </Col>
                {details.reasonText && 
                   <Col  xs={6} style={{paddingTop: 15 }}>
                    <Card.Subtitle className="text-muted">
                    {details.status == '1' || details.status == '2' ?  'Info' : 'Reason'} 
                  </Card.Subtitle>
                  <Card.Text>
                      {details.description}
                    </Card.Text>
                   </Col>
                }
              </Row>
            </Container>
          </Card.Body>
        </Card>
      </div>
    </Modal>,
    modalsRoot,
  );
};

export default WaitListInfoDetails;
