import { Box, Typography } from "@mui/material";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import { ReactChild, ReactComponentElement, ReactElement } from "react";
import { Button } from "react-bootstrap";
import { KTIcon } from "../../_metronic/helpers";

export default function MuiTable({
  isAddEnable = true,
  isBackEnable = false,
  isRowClickable = false,
  addLabel = "",
  handleAddClick,
  handleBackClick,
  hideFooterPagination = false,
  columns,
  data,
  tableRef,
  options,
  loading = false,
  children,
  handleCellClick,
  actionButtons,
  handlePagination,
  tableOptions,
  filters = null,
}: {
  isAddEnable?: boolean;
  isBackEnable?: boolean;
  hideFooterPagination?: boolean;
  isRowClickable?: boolean;
  addLabel?: string;
  handleAddClick?: () => void;
  handleBackClick?: () => void;
  handleCellClick?: (data: any) => any;
  columns?: GridColDef[];
  data?: any;
  tableRef?: any;
  options?: any;
  loading?: boolean;
  children?: ReactChild;
  actionButtons?: ReactComponentElement<any>;
  handlePagination?: (values: { page: number; pageSize: number }) => void;
  tableOptions?: any;
  filters?: ReactElement | null;
}) {
  return (
    <div className="w-100 d-flex flex-column">
      <div className="align-items-center align-self-end d-md-flex w-100" style={{paddingBottom:'24px'}}>
        {isBackEnable && (
          <div className="ms-auto" style={{ position: "absolute", left: 0 }}>
            <Button
              onClick={handleBackClick}
              className="d-flex align-items-center btn btn-secondary"
            >
              <KTIcon iconName="arrow-left" className="fs-1" />
              Back
            </Button>
          </div>
        )}
        {actionButtons}
        {isAddEnable && (
          <div className="ms-auto">
            <Button
              onClick={handleAddClick}
              className="d-flex align-items-center"
            >
              <KTIcon iconName="plus" className="fs-1" />
              {addLabel}
            </Button>
          </div>
        )}
      </div>
      {/* {isAddEnable && (
        <div
        className="w-100 mb-7"
        style={{
          height: 1,
          borderBottom:
            "1px dashed var(--bs-app-sidebar-light-separator-color)",
        }}
      ></div>
      )} */}
      <div>{children}</div>
      <Box
        sx={{
          width: "100%",
        }}
      >
        {filters}
        <DataGrid
          rows={data ?? []}
          columns={columns ?? []}
          pageSizeOptions={[10, 25, 50, 100]}
          ref={tableRef ?? null}
          pagination={true}
          getRowId={(data) => data?._id ?? data?.holeNumber}
          paginationMode="server"
          loading={loading}
          hideFooterPagination={hideFooterPagination ? true : false}
          initialState={{
            pagination: {
              paginationModel: {
                pageSize: tableOptions?.limit,
                page: tableOptions?.offset,
              },
            },
          }}
          disableColumnFilter={true}
          disableColumnMenu={true}
          disableColumnSelector={true}
          onCellClick={handleCellClick}
          onPaginationModelChange={handlePagination}
          rowCount={tableOptions?.totalRowCount}
          slots={{
            loadingOverlay: () => (
              <Typography
                variant="h4"
                fontWeight={500}
                fontSize={13}
                sx={noDataLoadingLabelStyle}
              >
                Loading...
              </Typography>
            ),
            noRowsOverlay: () => (
              <Typography
                variant="h4"
                fontWeight={500}
                fontSize={13}
                sx={noDataLoadingLabelStyle}
              >
                No Data Found
              </Typography>
            ),
          }}
          sx={{
            width: 1,
            "& .MuiDataGrid-overlayWrapper": {
              minHeight: "150px !important",
            },
            "& .MuiDataGrid-columnHeaderTitle": {
              // textTransform: "uppercase !important",
              // fontWeight: "400 !important",
              textAlign:"center"
            },
            // disable cell selection style
            ".MuiDataGrid-cell:focus": {
              outline: "none",
            },
            // pointer cursor on ALL rows
            "& .MuiDataGrid-row:hover": {
              cursor: [isRowClickable] ? "pointer" : "initial",
            },
            ".css-rtrcn9-MuiTablePagination-root": {
              marginRight: "15px",
            },
            ".css-levciy-MuiTablePagination-displayedRows, .css-rtrcn9-MuiTablePagination-root .MuiTablePagination-selectLabel":
              {
                marginTop: "10px !important",
              },
            ".css-whi0l6 .MuiTablePagination-selectLabel": {
              marginTop: "10px !important",
            },
            ".css-1chpzqh": {
              marginRight: "15px !important",
              marginTop: "10px !important",
            },
            "MuiToolbar-root MuiToolbar-gutters MuiToolbar-regular MuiTablePagination-toolbar css-8nphli":
              {
                marginRight: "15px !important",
              },
            "MuiButtonBase-root MuiIconButton-root MuiIconButton-colorInherit MuiIconButton-sizeMedium css-1deacqj":
              {
                marginRight: "10px !important",
              },
            ".css-vubbuv": {
              marginRight: "13px !important",
            },
          }}
          {...options}
        />
      </Box>
    </div>
  );
}

const noDataLoadingLabelStyle = {
  textAlign: "center",
  position: "relative",
  top: "30%",
};
