const BASE_URL = process.env.REACT_APP_BASE_URL;

export const userToken = localStorage.getItem("userToken");

// configure header's Content-Type as JSON
export const config = {
  headers: {
    ContentType: "application/json",
    accept: "application/json",
    Authorization: `Bearer ${userToken}`,
  },
};

export const APP_URLs = {
  mediaUpload: `${BASE_URL}/media/uploadpicture`,
  authEndPoints: {
    login: `${BASE_URL}/admin/signin`,
    logout: `${BASE_URL}/admin/logout`,
  },
  golfEndPoints: {
    getAllGolf: `${BASE_URL}/golf/all`,
    golfCommonAPI: `${BASE_URL}/golf`,
    activeDeactiveGolf: `${BASE_URL}/golf/active`,
    //holes:
    addEditGolfHoles: `${BASE_URL}/golf/hole`,
    // third-party-api
    addEditThirdPartyAPI: `${BASE_URL}/golf/api`,
  },
  termsAndCondition: {
    getTerms: `${BASE_URL}/static/terms`,
    addTerms: `${BASE_URL}/static/terms`,
  },
  privacyAndPolicy: {
    getPrivacy: `${BASE_URL}/static/privacy`,
    addPrivacy: `${BASE_URL}/static/privacy`,
  },
  paymentTerms: {
    getPaymentTerms: `${BASE_URL}/static/payment-terms`,
    addPaymentTerms: `${BASE_URL}/static/payment-terms`,
  },
  paymentPrivacy: {
    getPaymentPrivacy: `${BASE_URL}/static/payment-privacy`,
    addPaymentPrivacy: `${BASE_URL}/static/payment-privacy`,
  },
  aboutUs: {
    getAboutUs: `${BASE_URL}/static/about-us`,
    addAboutUs: `${BASE_URL}/static/about-us`,
  },
  subscription: {
    SubscriptionCommonAPI: `${BASE_URL}/subscription`,
  },
  commonEndPoints: {
    getCountries: `${BASE_URL}/auth/getCountries`,
    getStates: `${BASE_URL}/auth/getStates`,
    getCities: `${BASE_URL}/auth/getCities`,
  },
  faq: {
    genericFAQAPI: `${BASE_URL}/faq`,
  },
  golf:{
    genericCategoryGolf : `${BASE_URL}/golf/all`,
  },
  appUser: {
    appCustomerList: `${BASE_URL}/admin/userList`,
    addAdmin: `${BASE_URL}/admin/create-admin`,
    editAdmin: `${BASE_URL}/admin/edit-details`,
    suspendAdmin: `${BASE_URL}/admin/suspend-user`,
    adminList: `${BASE_URL}/admin/adminList`,
    deleteGolfAdmin: `${BASE_URL}/admin/removeAdmin`,
  },
  offers: {
    offersCommon: `${BASE_URL}/offer`,
  },
  banners: {
    bannerComnonApi: `${BASE_URL}/banner`,
  },
  company: {
    genericCompanyAPI: `${BASE_URL}/company`,
  },
  booking: {
    genericSlotBookingAPI: `${BASE_URL}/slotBooking`,
  },
  subscriptionInfo: {
    genericSubscriptionInfoAPI: `${BASE_URL}/subscriptionInfo`,
  },
  coupon: {
    genericCouponAPI: `${BASE_URL}/coupon`,
  },
  contactus: {
    getContactusList: `${BASE_URL}/contact-us`,
    adminContactUs: `${BASE_URL}/contact-us/contactAdmin`,
  },
  changeUserPassword: {
    genericAPI: `${BASE_URL}/user/change-password`,
  },
  membershipDescription: {
    genericMembershipDescription: `${BASE_URL}/static/membership-description`,
  },
  squezzMe: {
    getSquezzMeList: `${BASE_URL}/slotRequest`,
    updateRequestStatus: `${BASE_URL}/slotRequest/updateStatus`,
  },
  interest: {
    genericInterestAPI: `${BASE_URL}/interest`,
  },
  apparel: {
    genericApparelAPI: `${BASE_URL}/apparel`,
  },
  notify: {
    generiWaitingListAPI: `${BASE_URL}/notify`,
    genericWaitingUserNotifyAPI: `${BASE_URL}/notify/notifyUser`,
  },
  dashboard: {
    getDashboard: `${BASE_URL}/dashboard/analytics`,
  },
};
