import InfoIcon from "@mui/icons-material/Info";
import { Box, Tooltip } from "@mui/material";
// import { GridColDef } from "@mui/x-data-grid";
import React, { useEffect, useRef, useState } from "react";
import { Button, Card } from "react-bootstrap";
import { useMutation } from "react-query";
import { useNavigate } from "react-router-dom";
import { KTCard, KTIcon } from "../../_metronic/helpers";
import { getWaitingList, notify } from "../../api";
import MuiTable from "../../components/table/MuiTable";
import WaitListInfoDetails from "../../components/waitlist-info/waitlist-info-detail";
import { snackActions } from "../../helpers/SnackUtilsConfigurator";
import { formatDate } from "../../helpers/commonFunctions";
import useDebounce from "../../hooks/useDebounce";
// import WaitListInfoDetails from "../../components/waitlist-info/waitlist-info-detail";

const WaitingList: React.FC = () => {
  const [showDetailsPopup, setShowDetailsPopup] = useState(false);
  const [selectedRow, setSelectedRow] = useState<any>();
  const [isrowchecked, setIsRowChecked] = useState(false);
  const [inviteuserid, setInviteUserId] = useState();
  const [checkedIds, setCheckedIds] = useState<string[]>([]);
  const [tableOptions, setTableOptions] = useState({
    offset: 0,
    limit: 10,
    hasNextPage: true,
    hasPrevPage: true,
    totalRowCount: undefined,
    search: "",
  });
  const tableRef = useRef();
  const navigate = useNavigate();
  const debounceSearchValue: string = useDebounce(tableOptions?.search, 500);

  const {
    mutateAsync: getWaitingdata,
    data: waitingData,
    isLoading: isNotifyData,
  } = useMutation("squezz-me-list", getWaitingList);

  const {
    mutateAsync: notifyUser,
    isLoading: isNotifyUser,
    error: notifyError,
  } = useMutation("notify", notify);

  const handleSubmit = async (item: any) => {
    let bodyParams = {
      notifyIds: [item._id],
    };
    debugger
    let response = await notifyUser(bodyParams);
    if (response?.status) {
      snackActions.success(
        response?.message ?? "You have notified to the user",
      );
      getWaiting(tableOptions);
    } else {
      snackActions.error(response?.message ?? "Something went wrong!");
    }
  };

  console.log(checkedIds, "chceking idss ")
  const handleInviteall = async () => {
    let bodyParams = {
      notifyIds: checkedIds,
    };
    let response = await notifyUser(bodyParams);
    if (response?.status) {
      snackActions.success(
        response?.message ?? "You have notified to the user",
      );
      getWaiting(tableOptions);
    } else {
      snackActions.error(response?.message ?? "Something went wrong!");
    }
  };

  useEffect(() => {
    getWaiting(tableOptions);
  }, []);

  useEffect(() => {
    getWaiting({
      ...tableOptions,
      search: debounceSearchValue ? debounceSearchValue : "",
    });
  }, [debounceSearchValue]);

  const getWaiting = async (
    query:
      | {
        offset: number | string;
        limit: number;
        search: string;
      }
      | undefined,
  ) => {
    let response = await getWaitingdata(query);
    if (response?.status) {
      setTableOptions((prevState: any) => {
        return {
          ...prevState,
          offset: response?.data?.offset,
          limit: response?.data?.limit,
          hasNextPage: response?.data?.hasNextPage,
          hasPrevPage: response?.data?.hasPrevPage,
          totalRowCount: response?.data?.totalDocs,
        };
      });
    }
  };

  const handleInputChange = (e: any, data: any) => {
    const id = data?.row?._id; // Extract ID from data
    setInviteUserId(id)
    
    if (e?.target?.checked) {
      console.log(checkedIds);
      // Checkbox is checked, add ID to the array
      setCheckedIds([...checkedIds, id]);
      setIsRowChecked(true); // Set isRowChecked to true
    } else {
      // Checkbox is unchecked, remove ID from the array
      setCheckedIds(checkedIds.filter((checkedId) => checkedId !== id));
      
      // Check if there are still any checkboxes checked
      setIsRowChecked(checkedIds.filter((checkedId) => checkedId !== id).length > 0);
    }
    debugger
     // Log the updated checkedIds array
  };
  console.log(checkedIds);

  const columns: any = [
    {
      field : " ",
      headerName : " ",
      flex: 0.5,
      sortable : false,
      renderCell : (data:any)  => (
        data?.row.IsNotified ?  " " : (<input type="checkbox" style={{width: "20px", height: "15px"}} onChange={(e) => handleInputChange(e, data)}/>)
      )
    },
    {
      field: "name",
      headerName: "User Name",
      flex: 1,
      minWidth: 190,
      sortable: false,
      headerAlign: "center",
      align: "center",
      renderCell: (data: any) => (
        <Tooltip
          title={
            data?.row?.user ? (
              <h6 style={{ color: "white" }}>{data?.row?.user?.firstName + " " + data?.row?.user?.lastName}</h6>
            ) : (
              "N/A"
            )
          }
        >
          <span>
            {data?.row?.user
              ? data?.row?.user?.firstName + " " + data?.row?.user?.lastName
              : "N/A"}
          </span>
        </Tooltip>
      ),
    },
    {
      field: "golf course",
      headerName: "Golf Course",
      flex: 2,
      minWidth: 140,
      sortable: false,
      headerAlign: "center",
      align: "center",
      renderCell: (data: any) =>
        data?.row?.golf?.name ? data?.row?.golf?.name : "N/A",
    },
    {
      field: "date",
      headerName: " Date (MM/DD/YY)",
      flex: 1.5,
      sortable: false,
      minWidth: 140,
      headerAlign: "center",
      align: "center",
      renderCell: (data: any) =>
        data?.row?.bookingDate
          ? formatDate(data?.row?.bookingDate, "MM/dd/yy")
          : "N/A",
    },
    {
      field: "earliest",
      headerName: "Earliest Time",
      minWidth: 140,
      flex: 1.3,
      sortable: false,
      headerAlign: "center",
      align: "center",
      renderCell: (data: any) => `${data?.row?.earliestTime}`,
    },
    {
      field: "latest",
      headerName: "Latest Time",
      minWidth: 140,
      flex: 1.1,
      sortable: false,
      headerAlign: "center",
      align: "center",
      renderCell: (data: any) => `${data?.row?.latestTime}`,
    },
    {
      field: "person",
      headerName: "Person",
      minWidth: 95,
      flex: 0.8,
      sortable: false,
      headerAlign: "center",
      align: "center",
      renderCell: (data: any) => data?.row?.person ?? "N/A",
    },

    {
      field: "actions",
      headerName: "Actions",
      minWidth: 100,
      flex: 1,
      sortable: false,
      headerAlign: "center",
      align: "center",
      renderCell: (data: any) => (
        <Box display="flex" alignItems="center">
          <Tooltip title="Info">
            <InfoIcon
              onClick={() => {
                setSelectedRow(data.row);
                setShowDetailsPopup(true);
              }}
              sx={{ fontSize: 22, cursor: "pointer", marginRight: 1.5 }}
              color="primary"
            />
          </Tooltip>
          <Button
            type="submit"
            size="sm"
            variant={data?.row.IsNotified ? "secondary" : "primary"}
            disabled={data?.row.IsNotified}
            color={data?.row.IsNotified ? "disabled" : "success"}
            onClick={() => handleSubmit(data.row)}
          >
            {isNotifyUser ? "Loading" : "Invite"}
          </Button>
        </Box>
      ),
    },
  ];

  const handlePagination = (values: { page: number; pageSize: number }) => {
    let page = values?.page;
    let pageSize = values?.pageSize;
    let query = {
      offset:
        pageSize == tableOptions?.limit ? (page === 0 ? 0 : `${page}` + 0) : 0,
      limit: pageSize ?? 10,
      search: debounceSearchValue ?? "",
    };
    getWaiting(query);
  };

  const handleChangeFilters = (fieldName: string, value: string) => {
    setTableOptions((prevState: any) => {
      return {
        ...prevState,
        [fieldName]: value,
      };
    });
  };

  return (
    <Card className="p-6">
      <>
        <KTCard>
          <MuiTable
            isAddEnable={false}
            columns={columns}
            data={waitingData?.data?.docs ?? []}
            tableRef={tableRef}
            loading={isNotifyData}
            isRowClickable={true}
            handlePagination={handlePagination}
            tableOptions={tableOptions}
            filters={
              <Box pb={3} display="flex" alignItems="center">
                <Box className="top-search">
                  <label
                    className="form-label"
                    style={{ marginLeft: 5, fontWeight: "900", fontSize: "15.6px" }}
                  >
                    Search By Name
                  </label>
                  <KTIcon iconName="magnifier" className="fs-1" />
                  <input
                    type="text"
                    className="form-control form-control-lg form-control-solid"
                    placeholder="Search by name"
                    aria-label="Search"
                    onChange={(e: any) =>
                      handleChangeFilters("search", e?.target?.value)
                    }
                    value={tableOptions?.search}
                  />
                </Box>

                {isrowchecked && 
                <div className="ms-auto" style={{ marginTop: 32 }}>
                    <Button
                      className="d-flex align-items-center"
                      onClick={handleInviteall}
                    >
                      Invite All 
                    </Button>
                    {/* {isNotifyUser ? "Loading" : "Invite"} */}
                  </div>
                  }
              </Box>
            }
          />
        </KTCard>
        {showDetailsPopup && (
          <WaitListInfoDetails
            details={selectedRow}
            show={showDetailsPopup}
            handleClose={() => setShowDetailsPopup(false)}
          />
        )}
      </>
    </Card>
  );
};

export default WaitingList;
