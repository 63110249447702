import axios from "axios";
import CryptoJS from 'crypto-js';

const instance = axios.create();
 
instance.defaults.headers.post["Content-Type"] = "application/json";
instance.defaults.timeout = 1000 * 30;
 

const encryptPayload = (payload:any, secretKey:any, iv:any) => {
    const key = CryptoJS.enc.Base64.parse(secretKey);
    const ivParsed = CryptoJS.enc.Base64.parse(iv);
    const ciphertext = CryptoJS.AES.encrypt(JSON.stringify(payload), key, { iv: ivParsed }).toString();
    return ciphertext;
};

// request interceptor
instance.interceptors.request.use(
    (config) => {
      try {
        if (config?.method && typeof config.method === 'string' && config.method.toUpperCase() !== "GET") {
          const encryptedPayload = encryptPayload(config.data, process.env.REACT_APP_SECRET_KEY, process.env.REACT_APP_IV);
          config.data = { encryptedData: encryptedPayload };
        }
      } catch (error) {
        console.error('Error in request interceptor:', error);
      }
      
      return config;
    },
    (error) => {
      return Promise.reject(error);
    },
  );
  
 
// response interceptor
instance.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (error.response && error.response.status === 401) {
      console.log(error.response)
      localStorage.clear();
      window.location.reload();
    }
    return Promise.reject(error);
  },
);
 
export default instance;