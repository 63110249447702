/* eslint-disable jsx-a11y/anchor-is-valid */
import { useEffect } from "react";
import { Outlet, Route, Routes, useLocation } from "react-router-dom";
import { ContactUs } from "../../components/auth/ContactUs";
import { ForgotPassword } from "../../components/auth/ForgotPassword";
import { Login } from "../../components/auth/Login";
import { Registration } from "../../components/auth/Registration";

const AuthLayout = () => {
  const location = useLocation();

  useEffect(() => {
    document.body.classList.add("bg-body");
    return () => {
      document.body.classList.remove("bg-body");
    };
  }, []);

  return (
    <div style={{ minHeight: "100vh", overflow: "auto" }} className="position-relative d-flex  p-lg-15 p-md-10  align-items-center flex-column">
      <div className="bg-image-shape-box">
        <img className="login-shape" src={("/media/bg/bg-courner-shape.png")} />
        <img className="login-bg-image" src={("/media/bg/login-bg.png")} />
      </div>
      {/* <div className="d-grid h-100 w-50">
        <div className="position-absolute" style={{ left: "19%", top: "10%" }}>
          <img
            className="w-200px"
            // style={{ height: "100vh" }}
            src={toAbsoluteUrl("/media/app-logo/logo-light.png")}
          />
        </div>
        <img
          className="w-100 m-auto"
          style={{ height: "100vh" }}
          src={toAbsoluteUrl("/media/bg/image_1.png")}
        />
      </div> */}
      <div className="align-content-center g-0 h-100 px-md-10 px-lg-15 row w-100">
        {/* begin::Content */}
        <div className="align-items-center col-xl-4 col-lg-5 col-md-6 col-sm-8 col-9 d-flex h-100">
          {/* begin::Logo */}
          {/* <a href="#" className="mb-12">
          <img
            alt="Logo"
            src={toAbsoluteUrl("/media/logos/default.svg")}
            className="h-45px"
          />
        </a> */}
          {/* end::Logo */}
          {/* begin::Wrapper */}
          <div className="login-form-box mh-100  overflow-auto w-100">
            <div className="logo-box mb-3 mb-md-8">
              <figure>
                <img src={("/media/app-logo/logo.png")} />
              </figure>
            </div>
            <Outlet />
            {location.pathname === "/auth/login" && (
              <div className="d-flex justify-content-center align-items-center">
                <div>
                  <p className="p-0 m-0 text-center">
                    Copyright © 2024 New Nine Golf®. All rights reserved.
                  </p>
                </div>
                {/* <div className="mx-10"></div>
                <div className="d-flex align-items-center fw-bold fs-6">
                  <Link
                    to="/auth/contactus"
                    className="text-muted text-hover-primary px-1"
                  >
                    Contact Us
                  </Link>
                </div> */}
              </div>
            )}
          </div>
          {/* end::Wrapper */}
        </div>
        {/* end::Content */}
        {/* begin::Footer */}
        {/* end::Footer */}
      </div>
    </div>
  );
};

const AuthPage = () => (
  <Routes>
    <Route element={<AuthLayout />}>
      <Route path="login" element={<Login />} />
      <Route path="registration" element={<Registration />} />
      <Route path="forgot-password" element={<ForgotPassword />} />
      <Route path="contactus" element={<ContactUs />} />
      <Route index element={<Login />} />
    </Route>
  </Routes>
);

export { AuthPage };

