import { useFormik } from "formik";
import { useEffect, useState } from "react";
import { Button, Col, Container, Modal, Row } from "react-bootstrap";
import { createPortal } from "react-dom";
import { MultiSelect } from "react-multi-select-component";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { useMutation } from "react-query";
import "react-quill/dist/quill.snow.css";
import * as Yup from "yup";
import { KTIcon } from "../../_metronic/helpers";
import { addAdmin, editAdmin, getAllGolfClub } from "../../api";
import { snackActions } from "../../helpers/SnackUtilsConfigurator";

type Props = {
  show: boolean;
  handleClose: (isSubmit: boolean) => void;
  title?: string | undefined;
  isForEditProfile?: boolean;
  editData: {
    _id?: string | undefined;
    firstName: string;
    lastName: string;
    email: string;
    mobileNumber: any;
    countryCode: any;
    password: string;
    // logo: string;
    // bio: string;
    // dateOfBirth: string;
    countryId: number;
    stateId: number;
    cityId: number;
    zip: number;
    manageGolfs: string[];
    role: string[];
  };
};

interface ISelectedGolf {
  label: string;
  value: string;
}

const modalsRoot = document.getElementById("root-modals") || document.body;

const AddEditAdmin = ({
  show,
  handleClose,
  editData,
  title,
  isForEditProfile = false,
}: Props) => {
  const [img, setImg] = useState();
  const [selectedGolfs, setSelectedGolf] = useState<ISelectedGolf[] | []>([]);

  // const [isAdmin, setIsAdmin] = useState<boolean>(false)
  // const [isUser, setIsUser] = useState<boolean>(false)

  const validationSchema = Yup.object().shape({
    firstName: Yup.string().required("First name is required"),
    lastName: Yup.string().required("Last name is required"),
    email: Yup.string()
      .email("Wrong email format")
      .min(3, "Minimum 3 symbols")
      .max(50, "Maximum 50 symbols")
      .required("Email is required"),
    // password: Yup.string()
    //   .test(
    //     "is-distance",
    //     "Password must be alphanumeric and contains minimum eight characters and at least, one special character and one uppercase letter",
    //     (value) => passwordRegex.test(String(value)),
    //   )
    //   .min(3, "Minimum 3 symbols")
    //   .max(50, "Maximum 50 symbols")
    //   .required("Password is required"),
    // bio: Yup.string().required("Bio is required"),
    // dateOfBirth: Yup.string().required("Date of Birth is required"),
    // city: Yup.string().required("City is required"),
    // zip: Yup.string().required("Zip is required"),
    // state: Yup.string().required("State is required"),
    // country: Yup.string().required("Country is required"),
    mobileNumber: Yup.string()
      .required("Mobile number is required")
      .min(10, "Mobile number format is incorrect"),
    // logo: Yup.mixed().required("Logo is required"),
  });

  const formik: any = useFormik({
    initialValues: {
      firstName: editData?.firstName ?? "",
      lastName: editData?.lastName ?? "",
      email: editData?.email ?? "",
      // password: editData?.password ?? "",
      // bio: editData?.bio ?? "",
      // dateOfBirth: editData?.dateOfBirth ?? "",
      // city: editData?.cityId ?? "",
      // zip: editData?.zip ?? "",
      // state: editData?.stateId ?? "",
      // country: editData?.countryId ?? "",
      // logo: "",
      manageGolfs: editData?.manageGolfs ?? "",
      mobileNumber: editData?.mobileNumber ?? "",
      countryCode: editData?.countryCode ?? "",
    },
    validationSchema,
    onSubmit: async (values) => {
      if (!isForEditProfile && !selectedGolfs?.length) {
        return;
      }

      let bodyParams = {
        userId: editData ? editData?._id : undefined,
        firstName: values?.firstName ?? "",
        lastName: values?.lastName ?? "",
        mobileNumber: values?.mobileNumber
          ? values?.mobileNumber?.slice(`${values?.countryCode?.length}`)
          : "",
        countryCode: values?.countryCode != null && values?.countryCode !== "" ? !values?.countryCode.includes('+') ? `+${values?.countryCode}` : values?.countryCode : "1",
        email: values?.email ?? "",
        role: ["admin"],
        manageGolfs: isForEditProfile ? editData.manageGolfs : selectedGolfs?.length
          ? selectedGolfs?.map((golf: ISelectedGolf) => golf?.value)
          : [],
      };

      if (editData) {
        let response = await editAPI(bodyParams);
        if (response?.status) {
          snackActions.success(
            response?.message ?? "Added/Edited Successfully!",
          );
          formik.resetForm();
          if (isForEditProfile) {
            localStorage.setItem("userInfo", JSON.stringify(response?.data));
          }
          handleClose(true);
        } else {
          snackActions.error(response?.message ?? "Something went wrong!");
        }
      } else {
        let response = await addAPI(bodyParams);
        if (response?.status) {
          snackActions.success(
            response?.message ?? "Added/Edited Successfully!",
          );
          formik.resetForm();
          handleClose(true);
        } else {
          snackActions.error(response?.message ?? "Something went wrong!");
        }
      }
    },
  });
  // const countryValue = formik.values.country;
  // const stateValue = formik.values.state;

  // const { data: countryData, isLoading: isLoadingCountry } = useQuery(
  //   "countries",
  //   getCountries,
  // );

  const {
    mutateAsync: getGolfClubs,
    data: golfData,
    isLoading: isGettingGolfData,
  } = useMutation("golf-list", getAllGolfClub);

  // const {
  //   data: stateData,
  //   mutateAsync: getStateData,
  //   isLoading: isLoadingState,
  // } = useMutation("states", getStates);
  // const {
  //   data: cityData,
  //   mutateAsync: getCityData,
  //   isLoading: isLoadingCity,
  // } = useMutation("cities", getCities);

  const { mutateAsync: addAPI, isLoading: isAdding } = useMutation(
    "add-edit",
    addAdmin,
  );
  const { mutateAsync: editAPI, isLoading: isEditing } = useMutation(
    "add-edit",
    editAdmin,
  );

  useEffect(() => {
    getGolfClubs({ limit: 100000 });
  }, []);

  useEffect(() => {
    if (editData && golfData) {
      let golfList = golfData?.data?.docs;
      if (golfList?.length) {
        let selectedGolfList: any = [];
        golfList?.forEach((golf: any) => {
          if (editData?.manageGolfs?.includes(golf?._id)) {
            selectedGolfList?.push({ label: golf?.name, value: golf?._id });
          }
        });
        setSelectedGolf(selectedGolfList);
      }
      // setSelectedGolf(editData?.manageGolfs ?? [])
    }
  }, [editData, golfData]);

  useEffect(() => {
    if (editData) {
      formik.setFieldValue(
        "mobileNumber",
        editData?.countryCode + editData?.mobileNumber,
      );
    }
    // setIsAdmin(editData.role.includes("admin"));
    //   setIsUser(editData.role.includes("user"));
  }, [editData]);

  // useEffect(() => {
  //   if (countryValue) {
  //     getStateData({ countryId: countryValue });
  //   }
  // }, [countryValue]);

  // useEffect(() => {
  //   if (stateValue) {
  //     getCityData({ stateId: stateValue });
  //   }
  // }, [stateValue]);

  return createPortal(
    <Modal
      id="kt_modal_create_app"
      tabIndex={-1}
      aria-hidden="true"
      dialogClassName="modal-dialog modal-dialog-centered mw-700px"
      show={show}
      onHide={() => handleClose(false)}
      backdrop="static"
    >
      <div className="modal-header">
        <h2>{editData ? 'Edit' : 'Add'} Admin</h2>
        {/* begin::Close */}
        <div
          className="btn btn-sm btn-icon btn-active-color-primary"
          onClick={() => handleClose(false)}
        >
          <KTIcon className="fs-1" iconName="cross" />
        </div>
        {/* end::Close */}
      </div>

      <div className="p-6">
        <form onSubmit={formik.handleSubmit} noValidate className="form">
          <Container fluid>
            <Row >
              <Col xs={6}>
                <label className="form-label fw-bold">First Name</label>
                <input
                  type="text"
                  className="form-control form-control-lg form-control-solid mb-3 mb-lg-0"
                  placeholder="First Name"
                  aria-label="First Name"
                  {...formik.getFieldProps("firstName")}
                />
                {formik.touched.firstName && formik.errors.firstName && (
                  <div className="fv-plugins-message-container">
                    <div className="fv-help-block">
                      {formik.errors.firstName}
                    </div>
                  </div>
                )}
              </Col>
              <Col xs={6}>
                <label className="form-label fw-bold">Last Name</label>
                <input
                  type="text"
                  className="form-control form-control-lg form-control-solid mb-3 mb-lg-0"
                  placeholder="Last Name"
                  aria-label="Last Name"
                  {...formik.getFieldProps("lastName")}
                />
                {formik.touched.lastName && formik.errors.lastName && (
                  <div className="fv-plugins-message-container">
                    <div className="fv-help-block">
                      {formik.errors.lastName}
                    </div>
                  </div>
                )}
              </Col>
            </Row>

            <Row className="pt-6">
              <Col xs={6}>
                <label className="form-label fw-bold">Email</label>
                <input
                  placeholder="Email"
                  aria-label="Email"
                  {...formik.getFieldProps("email")}
                  className="form-control form-control-lg form-control-solid mb-3 mb-lg-0"
                  type="email"
                  name="email"
                  autoComplete="off"
                  disabled={editData ? true : false}
                  style={{
                    cursor: editData ? "not-allowed" : "initial",
                  }}
                />
                {formik.touched.email && formik.errors.email && (
                  <div className="fv-plugins-message-container">
                    <div className="fv-help-block">{formik.errors.email}</div>
                  </div>
                )}
              </Col>
              <Col xs={6}>
                <label className="form-label fw-bold">Mobile Number</label>
                <PhoneInput
                  country={"us"}
                  // variant="outlined"
                  onBlur={formik.handleBlur}
                  value={formik.values.mobileNumber}
                  onChange={(value: string, country: any) => {
                    formik.setFieldValue(
                      "countryCode",
                      country?.dialCode || "",
                    );
                    formik.setFieldValue("mobileNumber", value || "");
                  }}
                  inputStyle={{
                    background: "#f9f9f9",
                    border: "none",
                  }}
                  inputClass="form-control form-control-lg form-control-solid mb-3 mb-lg-0"
                  // disableDropdown={true}
                  // disableCountryCode={true}
                  // onlyCountries={["us"]}
                  disabled={editData ? true : false}
                />
                {formik.touched.mobileNumber && formik.errors.mobileNumber && (
                  <div className="fv-plugins-message-container">
                    <div className="fv-help-block">
                      {formik.errors.mobileNumber}
                    </div>
                  </div>
                )}
              </Col>
              {/* <Col xs={6}>
                <label className="form-label fw-bold">Password</label>
                <input
                  type="text"
                  autoComplete="off"
                  placeholder="Password"
                  aria-label="Password"
                  {...formik.getFieldProps("password")}
                  className="form-control form-control-lg form-control-solid mb-3 mb-lg-0"
                />
                {formik.touched.password && formik.errors.password && (
                  <div className="fv-plugins-message-container">
                    <div className="fv-help-block">
                      {formik.errors.password}
                    </div>
                  </div>
                )}
              </Col> */}
            </Row>

            {!isForEditProfile &&
              <div className="pt-6 row">
                <Col xs={6} style={{ height: '30vh', overflowY: 'auto' }}>
                  <label className="form-label fw-bold">Select Golf Course</label>
                  <MultiSelect
                    options={
                      golfData && !isGettingGolfData
                        ? golfData?.data?.docs?.map((d: any) => {
                          return { label: d?.name, value: d?._id };
                        })
                        : []

                    }
                    value={selectedGolfs}
                    onChange={setSelectedGolf}
                    labelledBy="Select"
                    className="form-control form-control-lg form-control-solid p-1"
                  />
                  {isForEditProfile
                    ? null
                    : formik.touched.manageGolfs &&
                    !selectedGolfs?.length && (
                      <div className="fv-plugins-message-container">
                        <div className="fv-help-block">
                          Golf selection is required
                        </div>
                      </div>
                    )}
                </Col>
                {/* </div>
            } */}

                {/* <Row>
              <div className="fv-row">
                <label
                  className="form-check form-check-inline"
                  style={{ paddingTop: 20 }}>
                  Admin
                  <input
                    className="form-check-input"
                    type="checkbox"
                    id="kt_role_admin"
                    onChange={(e) => setIsAdmin(e.target.checked)}
                    checked={isAdmin}
                  //checked={editData.role.includes("admin")}
                  />
                </label>
                <label
                  className="form-check form-check-inline"
                  htmlFor="kt_login_toc_agree"
                  style={{ paddingTop: 20 }}>
                  User
                  <input
                    className="form-check-input"
                    type="checkbox"
                    id="kt_role_user"
                    onChange={(e) => setIsUser(e.target.checked)}
                    checked={isUser}
                  />
                </label>
              </div>
            </Row> */}


                {/* <Row className="pt-6">
              <Col xs={6}>
                <label className="form-label fw-bold">Date of Birth</label>
                <input
                  type="date"
                  className="form-control form-control-lg form-control-solid mb-3 mb-lg-0"
                  placeholder="Start Date"
                  aria-label="Start Date"
                  {...formik.getFieldProps("dateOfBirth")}
                />
                {formik.touched.dateOfBirth && formik.errors.dateOfBirth && (
                  <div className="fv-plugins-message-container">
                    <div className="fv-help-block">
                      {formik.errors.dateOfBirth}
                    </div>
                  </div>
                )}
              </Col>
              <Col xs={6}>
                <label className="form-label fw-bold">Upload Logo</label>
                <input
                  type="file"
                  accept="image/*"
                  className="form-control form-control-lg form-control-solid mb-3 mb-lg-0"
                  placeholder="Upload Logo"
                  aria-label="Upload Logo"
                  {...formik.getFieldProps("logo")}
                />
                {formik.touched.logo && formik.errors.logo && (
                  <div className="fv-plugins-message-container">
                    <div className="fv-help-block">{formik.errors.logo}</div>
                  </div>
                )}
              </Col>
            </Row> */}

                {/* <label className="col-lg-4 col-form-label fw-bold fs-6 mt-3">
              <span className="required">Address</span>
            </label> */}

                {/* <Row>
              <Col xs={6} className="pt-3">
                <label className="form-label fw-bold">Country</label>
                <select
                  disabled={isLoadingCountry}
                  className="form-select form-select-solid form-select-lg"
                  {...formik.getFieldProps("country")}
                >
                  <option value="">Select Country</option>
                  {countryData?.data
                    ? countryData?.data?.map((country: any) => (
                        <option
                          value={country?.countryId}
                          key={country?._id}
                        >{`${country?.name} (${country?.code})`}</option>
                      ))
                    : null}
                </select>
                {formik.touched.country && formik.errors.country && (
                  <div className="fv-plugins-message-container">
                    <div className="fv-help-block">{formik.errors.country}</div>
                  </div>
                )}
              </Col>

              <Col xs={6} className="pt-3">
                <label className="form-label fw-bold">State</label>
                <select
                  disabled={isLoadingState}
                  className="form-select form-select-solid form-select-lg"
                  {...formik.getFieldProps("state")}
                >
                  <option value="">Select State</option>
                  {stateData?.data
                    ? stateData?.data?.map((state: any) => (
                        <option value={state?.stateId} key={state?._id}>
                          {state?.name}
                        </option>
                      ))
                    : null}
                </select>
                {formik.touched.state && formik.errors.state && (
                  <div className="fv-plugins-message-container">
                    <div className="fv-help-block">{formik.errors.state}</div>
                  </div>
                )}
              </Col>

              <Col xs={6} className="pt-3">
                <label className="form-label fw-bold">City</label>
                <select
                  disabled={isLoadingCity}
                  className="form-select form-select-solid form-select-lg"
                  {...formik.getFieldProps("city")}
                >
                  <option value="">Select City</option>
                  {cityData?.data
                    ? cityData?.data?.map((city: any) => (
                        <option value={city?.cityId} key={city?._id}>
                          {city?.name}
                        </option>
                      ))
                    : null}
                </select>
                {formik.touched.city && formik.errors.city && (
                  <div className="fv-plugins-message-container">
                    <div className="fv-help-block">{formik.errors.city}</div>
                  </div>
                )}
              </Col>

              <Col xs={6} className="pt-3">
                <label className="form-label fw-bold">Zip</label>
                <input
                  type="number"
                  className="form-control form-control-lg form-control-solid mb-3 mb-lg-0"
                  placeholder="Zip"
                  aria-label="Zip"
                  {...formik.getFieldProps("zip")}
                />
                {formik.touched.zip && formik.errors.zip && (
                  <div className="fv-plugins-message-container">
                    <div className="fv-help-block">{formik.errors.zip}</div>
                  </div>
                )}
              </Col>
            </Row> */}

                {/* <Row className="pt-6">
              <Col xs={12}>
                <label className="form-label fw-bold">Bio</label>
                <textarea
                  className="form-control form-control-lg form-control-solid mb-3 mb-lg-0"
                  placeholder="Bio"
                  aria-label="Bio"
                  {...formik.getFieldProps("bio")}
                  cols={4}
                  rows={4}
                />
                {formik.touched.bio && formik.errors.bio && (
                  <div className="fv-plugins-message-container">
                    <div className="fv-help-block">Bio is required</div>
                  </div>
                )}
              </Col>
            </Row> */}
                {/* </Container> */}
                <Col xs={6} style={{ alignItems: 'end', justifyContent: 'end', marginTop: '3px', display: 'flex' }}>
                  {/* <div className=" w-100 d-flex align-items-end !important justify-content-end mt-3"> */}
                  <Button
                    onClick={() => {
                      formik.resetForm();
                      handleClose(false);
                    }}

                    size="lg"
                    variant="secondary"
                  >
                    Cancel
                  </Button>
                  <div className="w-15px"></div>
                  <Button type="submit" size="lg" variant="primary">
                    {isAdding || isEditing ? "Loading..." : "Submit"}
                  </Button>
                  {/* </div> */}
                </Col>
              </div>
            }
          </Container>

        </form>
      </div>
    </Modal>,
    modalsRoot,
  );
};

export default AddEditAdmin;
