import { createSlice } from "@reduxjs/toolkit";

// Define initial state
const initialState = {
  title: "Dashboard",
};

// Create a slice
const dataSlice = createSlice({
  name: "header", // Slice name
  initialState,
  reducers: {
    setData(state, action) {
      state.title = action.payload;
    },
  },
});

// Export actions
export const { setData } = dataSlice.actions;

// Export reducer
export default dataSlice.reducer;
