import { useFormik } from "formik";
import { Button, Modal } from "react-bootstrap";
import Form from 'react-bootstrap/Form';
import { createPortal } from "react-dom";
import { useMutation } from "react-query";
import * as Yup from "yup";
import { KTIcon } from "../../_metronic/helpers";
import { updateSquezzMeStatus } from "../../api";
import { ISquezzMeInfoDetails } from "../../constants/types";
import { snackActions } from "../../helpers/SnackUtilsConfigurator";

type Props = {
  show: boolean;
  handleClose: (isSubmit: boolean) => void;
  details: ISquezzMeInfoDetails;
};

const modalsRoot = document.getElementById("root-modals") || document.body;

const RejectSquezzMe = ({ show, handleClose, details }: Props) => {
  const { mutateAsync: updateStatus, isLoading: isAddingEditing } = useMutation(
    "update-status",
    updateSquezzMeStatus,
  );

  const validationSchema = Yup.object().shape({
    reason: Yup.string()
      .trim() // Remove leading and trailing whitespace
      .required("Reason Field is required and should not be blank"),
    // .test(
    //   "no-blank-spaces",
    //   "Blank spaces are not allowed",
    //   (value) => !/\s/.test(value),
    // ),
  });

  const formik = useFormik({
    initialValues: {
      reason: "",
    },
    validationSchema,
    onSubmit: async (values) => {
      let bodyParams = {
        id: details?._id ? details?._id : undefined,
        status: 3,
        reasonText: values.reason
      };
      let response = await updateStatus(bodyParams);
      if (response?.status) {
        snackActions.success(response?.message ?? "Status Updated Successfully!");
        formik.resetForm();
        handleClose(true);
      } else {
        snackActions.error(response?.message ?? "Something went wrong!");
      }
    },
  });

  return createPortal(
    <Modal
      id="kt_modal_create_app"
      tabIndex={-1}
      aria-hidden="true"
      dialogClassName="modal-dialog modal-dialog-centered mw-600px"
      show={show}
      onHide={() => handleClose(false)}
      backdrop="static"
    >
      <div className="modal-header">
        <h2>Reject Request</h2>
        {/* begin::Close */}
        <div
          className="btn btn-sm btn-icon btn-active-color-primary"
          onClick={() => handleClose(false)}
        >
          <KTIcon className="fs-1" iconName="cross" />
        </div>
        {/* end::Close */}
      </div>

      <div className="p-6">
        <form onSubmit={formik.handleSubmit} noValidate className="form">
          <div className="pt-6">
            <label className="form-label fw-bold">Reason</label>
            <Form>
              <Form.Group className="mb-3">
                <Form.Control as="textarea" rows={3}
                  {...formik.getFieldProps("reason")}
                  onChange={(e) => formik.handleChange(e)} />
              </Form.Group>
            </Form>
            {formik.touched.reason && formik.errors.reason && (
              <div className="fv-plugins-message-container">
                <div className="fv-help-block">
                  {formik.errors.reason}
                </div>
              </div>
            )}
          </div>
          <div className="w-100 d-flex p-5 align-items-center justify-content-end">
            <Button
              onClick={() => {
                formik.resetForm();
                handleClose(false);
              }}
              size="lg"
              variant="secondary"
            >
              Cancel
            </Button>
            <div className="w-15px"></div>
            <Button type="submit" size="lg" variant="primary">
              {isAddingEditing ? "Loading..." : "Submit"}
            </Button>
          </div>
        </form>
      </div>
    </Modal>,
    modalsRoot,
  );
};

export default RejectSquezzMe;
