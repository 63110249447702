import clsx from "clsx";
import { FC } from "react";
import { useDispatch } from "react-redux";
import { useLocation } from "react-router";
import { Link } from "react-router-dom";
import { setData } from "../../../../../store/slice/header/headerSlice";
import { checkIsActive, WithChildren } from "../../../../helpers";
import { useLayout } from "../../../core";

type Props = {
  to: string;
  title: string;
  icon?: string;
  fontIcon?: string;
  hasBullet?: boolean;
};

const SidebarMenuItem: FC<Props & WithChildren> = ({
  children,
  to,
  title,
  icon,
  fontIcon,
  hasBullet = false,
}) => {
  const { pathname } = useLocation();
  const isActive = checkIsActive(pathname, to);
  const { config } = useLayout();
  const { app } = config;
  const dispatch = useDispatch(); // Get dispatch function
  const HandleMenuClick = async () => {
    dispatch(setData(title));
  };

  return (
    <div className="menu-item">
      <Link
        className={clsx("menu-link without-sub", { active: isActive })}
        to={to}
        onClick={() => HandleMenuClick()}
      >
        {hasBullet && (
          <span className="menu-bullet">
            <span className="bullet bullet-dot"></span>
          </span>
        )}
        {icon && app?.sidebar?.default?.menu?.iconType === "svg" && (
          <span className="menu-icon">
            {" "} {getIcons(icon)}
            {/* <KTIcon iconName={icon} className="fs-1" /> */}
          </span>
        )}
        {fontIcon && app?.sidebar?.default?.menu?.iconType === "font" && (
          <i className={clsx("bi fs-3", fontIcon)}></i>
        )}
        <span className="menu-title">{title}</span>
      </Link>
      {children}
    </div>
  );
};

function getIcons(iconTag: string): JSX.Element | null {
  if (iconTag === "abstract-3") {
    return (
      <i className="ki-duotone ki-abstract-3 fs-1"><span className="path1 "></span><span className="path2 "></span></i>
    );
  } 
  else if (iconTag === "dashboard") {
    return (
      <svg xmlns="http://www.w3.org/2000/svg" width="24px" height="24px" viewBox="0 0 24 24" >
        <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
            <rect x="0" y="0" width="24" height="24"/>
            <path d="M3,4 L20,4 C20.5522847,4 21,4.44771525 21,5 L21,7 C21,7.55228475 20.5522847,8 20,8 L3,8 C2.44771525,8 2,7.55228475 2,7 L2,5 C2,4.44771525 2.44771525,4 3,4 Z M3,10 L13,10 C13.5522847,10 14,10.4477153 14,11 L14,13 C14,13.5522847 13.5522847,14 13,14 L3,14 C2.44771525,14 2,13.5522847 2,13 L2,11 C2,10.4477153 2.44771525,10 3,10 Z M3,16 L13,16 C13.5522847,16 14,16.4477153 14,17 L14,19 C14,19.5522847 13.5522847,20 13,20 L3,20 C2.44771525,20 2,19.5522847 2,19 L2,17 C2,16.4477153 2.44771525,16 3,16 Z" fill="#fff"/>
            <rect fill="#fff" opacity="0.3" x="16" y="10" width="5" height="10" rx="1"/>
        </g>
      </svg>
    );
  }
  else if (iconTag === "banner") {
    return (
      <i className="ki-duotone ki-picture fs-1"><span className="path1 "></span><span className="path2 "></span></i>
    );
  }
  else if(iconTag === "user-tick")
  {
    return(
      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
        <path d="M11.4898 11.9601C14.2236 11.9601 16.4398 9.74387 16.4398 7.01006C16.4398 4.27625 14.2236 2.06006 11.4898 2.06006C8.75599 2.06006 6.53979 4.27625 6.53979 7.01006C6.53979 9.74387 8.75599 11.9601 11.4898 11.9601Z" fill="#ECEBDF"/>
        <path d="M17.4299 13C16.6366 13.0001 15.8613 13.236 15.2025 13.6778C14.5436 14.1195 14.031 14.7472 13.7298 15.481C13.4286 16.2149 13.3523 17.0217 13.5108 17.7989C13.6692 18.5762 14.0552 19.2888 14.6196 19.8462C15.184 20.4035 15.9014 20.7805 16.6806 20.9291C17.4598 21.0778 18.2656 20.9914 18.9956 20.681C19.7255 20.3705 20.3467 19.8501 20.7802 19.1857C21.2136 18.5214 21.4398 17.7432 21.4299 16.95C21.4167 15.8978 20.9895 14.8932 20.2408 14.1538C19.492 13.4145 18.4821 12.9999 17.4299 13ZM19.5499 16.44L17.8599 18.6C17.7896 18.6863 17.7012 18.7561 17.601 18.8045C17.5008 18.8529 17.3911 18.8786 17.2799 18.88C17.1658 18.8804 17.0532 18.8544 16.9509 18.8041C16.8485 18.7538 16.7592 18.6805 16.6899 18.59L15.4799 17C15.3711 16.8478 15.324 16.66 15.3482 16.4744C15.3723 16.2889 15.4658 16.1194 15.6099 16C15.7621 15.8912 15.9499 15.8442 16.1354 15.8683C16.321 15.8924 16.4905 15.9859 16.6099 16.13L17.2399 16.93L18.3399 15.53C18.3996 15.4532 18.4739 15.3889 18.5585 15.3408C18.6431 15.2927 18.7364 15.2617 18.8329 15.2496C18.9295 15.2375 19.0275 15.2446 19.1214 15.2704C19.2152 15.2962 19.303 15.3402 19.3799 15.4C19.4567 15.4598 19.521 15.534 19.5691 15.6187C19.6172 15.7033 19.6482 15.7965 19.6603 15.8931C19.6723 15.9896 19.6653 16.0877 19.6395 16.1815C19.6137 16.2753 19.5696 16.3632 19.5099 16.44H19.5499Z" fill="#ECEBDF"/>
        <g opacity="0.3">
        <path d="M20.3599 20.77C20.1369 19.5229 19.5884 18.3569 18.7699 17.39L17.8599 18.55C17.7896 18.6363 17.7012 18.7061 17.601 18.7545C17.5008 18.8028 17.3912 18.8286 17.2799 18.83C17.1659 18.8304 17.0533 18.8044 16.9509 18.7541C16.8486 18.7038 16.7592 18.6305 16.6899 18.54L15.4799 17C15.4007 16.8976 15.3505 16.7758 15.3346 16.6473C15.3188 16.5189 15.3379 16.3885 15.3899 16.27C15.3402 16.1543 15.3217 16.0277 15.3362 15.9027C15.3507 15.7776 15.3977 15.6586 15.4726 15.5574C15.5475 15.4562 15.6476 15.3764 15.7629 15.326C15.8782 15.2756 16.0048 15.2563 16.1299 15.27C14.6963 14.5276 13.1043 14.1433 11.4899 14.15C6.99989 14.14 3.25989 17 2.61989 20.77C2.60508 20.9326 2.62571 21.0966 2.68035 21.2505C2.73499 21.4044 2.82234 21.5447 2.9364 21.6616C3.05046 21.7785 3.1885 21.8693 3.34102 21.9277C3.49354 21.9861 3.65692 22.0108 3.81989 22H19.1699C19.3321 22.0093 19.4943 21.9835 19.6456 21.9244C19.7969 21.8653 19.9337 21.7743 20.0466 21.6576C20.1596 21.5408 20.246 21.4011 20.3001 21.2479C20.3541 21.0948 20.3745 20.9318 20.3599 20.77Z" fill="#ECEBDF"/>
        </g>
    </svg>
    )
  }
  else if(iconTag === "notification-on")
  {
    return(
      <i className="ki-duotone ki-user-edit fs-1"><span className="path1"></span><span className="path2"></span><span className="path3"></span></i>
    )
  }
  else if(iconTag === "membership")
  {
    return(
      <i className="ki-duotone ki-address-book fs-1"><span className="path1"></span><span className="path2"></span><span className="path3"></span></i>
    )
  }
  else if(iconTag === "about-app")
  {
    return(
      <i className="ki-duotone ki-information-2 fs-1"><span className="path1"></span><span className="path2"></span><span className="path3"></span></i>
    )
  }
  
  else if(iconTag === "membership")
  {
    return(
      <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path opacity="0.3" d="M16.7 2.01001H7.31001C4.3829 2.01001 2.01001 4.3829 2.01001 7.31001V16.7C2.01001 19.6271 4.3829 22 7.31001 22H16.7C19.6271 22 22 19.6271 22 16.7V7.31001C22 4.3829 19.6271 2.01001 16.7 2.01001Z" fill="#ECEBDF"/>
      <path d="M9.0001 6.13013C8.46967 6.13013 7.96096 6.34084 7.58588 6.71591C7.21081 7.09099 7.0001 7.59969 7.0001 8.13013V16.3901C6.99731 16.5693 7.05398 16.7443 7.16125 16.8877C7.26853 17.0312 7.42034 17.1351 7.59294 17.1831C7.76554 17.2311 7.94919 17.2206 8.11514 17.1531C8.2811 17.0856 8.41999 16.965 8.5101 16.8101L8.9201 16.1101C8.97963 16.0062 9.06554 15.9199 9.16914 15.8599C9.27274 15.7998 9.39036 15.7682 9.5101 15.7682C9.62984 15.7682 9.74745 15.7998 9.85106 15.8599C9.95466 15.9199 10.0406 16.0062 10.1001 16.1101L10.6401 17.0501C10.7792 17.2888 10.9785 17.4868 11.218 17.6244C11.4575 17.762 11.7289 17.8344 12.0051 17.8344C12.2813 17.8344 12.5527 17.762 12.7922 17.6244C13.0317 17.4868 13.231 17.2888 13.3701 17.0501L13.9001 16.1201C13.961 16.0164 14.0479 15.9304 14.1523 15.8707C14.2567 15.8109 14.3748 15.7795 14.4951 15.7795C14.6154 15.7795 14.7335 15.8109 14.8379 15.8707C14.9423 15.9304 15.0292 16.0164 15.0901 16.1201L15.4801 16.7901C15.5724 16.9416 15.7114 17.0591 15.8762 17.1248C16.0409 17.1906 16.2225 17.2012 16.3938 17.155C16.5651 17.1088 16.7168 17.0083 16.8261 16.8686C16.9354 16.7289 16.9965 16.5575 17.0001 16.3801V8.13013C17.0001 7.59969 16.7894 7.09099 16.4143 6.71591C16.0392 6.34084 15.5305 6.13013 15.0001 6.13013H9.0001Z" fill="#ECEBDF"/>
      </svg>
    )
  }
  else if(iconTag === "information")
  {
    return(
      <i className="ki-duotone ki-information fs-1"><span className="path1"></span><span className="path2"></span><span className="path3"></span></i>
    )
  }
  else if(iconTag === "squeez")
  {
    return(
      <i className="ki-duotone ki-archive-tick fs-1"><span className="path1"></span><span className="path2"></span></i>
    )
  }
  else if(iconTag === "waiting")
  {
    return(
      <i className="ki-duotone ki-notepad fs-1"><span className="path1"></span><span className="path2"></span><span className="path3"></span><span className="path4"></span><span className="path5"></span></i>
    )
  }
  else if(iconTag === "bookmark")
  {
    return(
      <i className="ki-duotone ki-calendar-tick fs-1"><span className="path1"></span><span className="path2"></span><span className="path3"></span><span className="path4"></span><span className="path5"></span><span className="path6"></span></i>
    )
  }
  
  else if(iconTag === "disconnect")
  {
    return(
      <i className="ki-duotone ki-send fs-1"><span className="path1"></span><span className="path2"></span></i>
    )
  }
  else if(iconTag === "abstract-32")
  {
    return(
      <i className="ki-duotone ki-abstract-32 fs-1"><span className="path1"></span><span className="path2"></span></i>
    )
  }
  else if(iconTag === "subscription")
  {
    return(
      <i className="ki-duotone ki-user-tick fs-1"><span className="path1"></span><span className="path2"></span><span className="path3"></span></i>
    )
  }
  else if(iconTag === "user")
  {
    return(
      <i className="ki-duotone ki-user fs-1"><span className="path1"></span><span className="path2"></span></i>
    )
  }
  else if(iconTag === "termsConditions")
  {
    return(
      <i className="ki-duotone ki-document fs-1"><span className="path1"></span><span className="path2"></span></i>
    )
  }
  else if(iconTag === "privacy-policy")
  {
    return(
      <i className="ki-duotone ki-tablet-text-down fs-1"><span className="path1"></span><span className="path2"></span><span className="path3"></span><span className="path4"></span></i>
    )
  }
  
  else if(iconTag === "question-2")
  {
    return(
      <i className="ki-duotone ki-question-2 fs-1"><span className="path1"></span><span className="path2"></span><span className="path3"></span></i>
    )
  }
 
  return null; // Or handle other cases accordingly
}
 
export default getIcons;

export { SidebarMenuItem };
