/* eslint-disable jsx-a11y/anchor-is-valid */
import clsx from "clsx";
import CryptoJS from 'crypto-js';
import { useFormik } from "formik";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import * as Yup from "yup";
import { toAbsoluteUrl } from "../../_metronic/helpers";
import { ILoginUserPayload } from "../../constants/types";
import { userLogin } from "../../store/slice/auth/authActions";

const loginSchema: any = Yup.object().shape({
  email: Yup.string()
    .email("Wrong email format")
    .min(3, "Minimum 3 symbols")
    .max(50, "Maximum 50 symbols")
    .required("Email is required"),
  password: Yup.string()
    .min(3, "Minimum 3 characters required")
    .max(50, "Maximum 50 characters")
    .required("Password is required"),
  acceptTerms: Yup.bool().required("You must accept the terms and conditions"),
});

const initialValues = {
  email: "",
  password: "",
  acceptTerms: false,
};

export function Login() {
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();

  const formik: any = useFormik({
    initialValues,
    validationSchema: loginSchema,
    onSubmit: async (values) => {
      setLoading(true);
      const payload: ILoginUserPayload = {
        email: values.email,
        password:CryptoJS.SHA256(values.password).toString(),
      };
      
      await dispatch(userLogin(payload));
      setLoading(false);
    },
  });

  return (
    <form
      className="form w-100"
      onSubmit={formik.handleSubmit}
      noValidate
      id="kt_login_signin_form"
    >
      {/* begin::Heading */}
      <div className="text-center mb-8">
        {/* begin::Title */}
        <h1 className="fw-bolder mb-3">Sign In</h1>
        {/* end::Title */}
        <p>Welcome to New Nine Golf</p>
      </div>
      {/* end::Heading */}

      {/* begin::Form group */}
      <div className="fv-row mb-4">
        {/* <label className="form-label fs-6 fw-bolder text-dark">Email</label> */}
        <input
          placeholder="Email"
          {...formik.getFieldProps("email")}
          className={clsx(
            "form-control",
            { "is-invalid": formik.touched.email && formik.errors.email },
            {
              "is-valid": formik.touched.email && !formik.errors.email,
            },
          )}
          type="email"
          name="email"
          autoComplete="off"
        />
        {formik.touched.email && formik.errors.email && (
          <div className="fv-plugins-message-container">
            <span className="alert text-danger">{formik.errors.email}</span>
          </div>
        )}
      </div>
      {/* end::Form group */}

      {/* begin::Form group */}
      <div className="fv-row mb-4">
        {/* <label className="form-label fw-bolder text-dark fs-6 mb-0">
          Password
        </label> */}
        <input
          type="password"
          placeholder="Password"
          autoComplete="off"
          {...formik.getFieldProps("password")}
          className={clsx(
            "form-control",
            {
              "is-invalid": formik.touched.password && formik.errors.password,
            },
            {
              "is-valid": formik.touched.password && !formik.errors.password,
            },
          )}
        />
        {formik.touched.password && formik.errors.password && (
          <div className="fv-plugins-message-container">
            <div className="fv-help-block">
              <span className="alert text-danger">{formik.errors.password}</span>
            </div>
          </div>
        )}
      </div>
      {/* end::Form group */}

      {/* begin::Form group */}
      <div className="d-flex flex-stack flex-wrap gap-3 fw-semibold mb-3 pt-2">
        <div className="fv-row">
          <label
            className="form-check form-check-inline"
            htmlFor="kt_login_toc_agree"
          >
            <input
              className="form-check-input"
              type="checkbox"
              id="kt_login_toc_agree"
              {...formik.getFieldProps("acceptTerms")}
            />
            <span>
              I accept the{""}
              <a
                href={toAbsoluteUrl("/terms.html")}
                target="_blank"
                className="ms-1 link-primary"
              >
                Terms & Conditions
              </a>
            </span>
          </label>
          {formik.touched.acceptTerms && formik.errors.acceptTerms && (
            <div className="fv-plugins-message-container">
              <div className="fv-help-block">
                <span role="alert text-danger">{formik.errors.acceptTerms}</span>
              </div>
            </div>
          )}
        </div>
        {/* <Link to="/auth/forgot-password" className="link-primary">
          Forgot Password ?
        </Link> */}
      </div>
      {/* end::Form group */}

      {/* begin::Action */}
      <div className="d-grid mb-3">
        <button
          type="submit"
          id="kt_sign_in_submit"
          className="btn btn-primary"
          disabled={
            formik.isSubmitting || !formik.isValid || !formik.values.acceptTerms
          }
        >
          {!loading && <span className="indicator-label">Login</span>}
          {loading && (
            <span className="indicator-progress" style={{ display: "block" }}>
              Please wait...
              <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
            </span>
          )}
        </button>
        <div className="mt-5">
          <Link to="/auth/forgot-password" className="link-primary_ d-flex justify-content-end">
            Forgot Password ?
          </Link>
        </div>
      </div>
      {/* end::Action */}

      {/* <div className="text-gray-500 text-center fw-semibold fs-6">
        Not a Member yet?{" "}
        <Link to="/auth/registration" className="link-primary">
          Sign up
        </Link>
      </div> */}
    </form>
  );
}
