import { FC, Suspense, lazy } from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import TopBarProgress from "react-topbar-progress-indicator";
import { getCSSVariableValue } from "../_metronic/assets/ts/_utils";
import { WithChildren } from "../_metronic/helpers";
import { MasterLayout } from "../_metronic/layout/MasterLayout";
import { isSuperAdmin } from "../hooks/useUserInfo";
import GolfAdmin from "../pages/app-user/GolfAdmin";
import ApparelDetails from "../pages/apparel/ApparelDetails";
import Coupon from "../pages/coupon/Coupon";
import DashboardPage from "../pages/dashboard/DashboardPage";
import Preference from "../pages/preference/Preference";
import PreferenceDetails from "../pages/preference_details/PreferenceDetails";
import SquezzMe from "../pages/squezz-me/SquezzMe";
import SubscriptionInfo from "../pages/subscription-info/SubscriptionInfo";
import WaitingList from "../pages/waiting/waitingList";

const PrivateRoutes = () => {
  const ProfilePage = lazy(() => import("../pages/profile/ProfilePage"));
  const WizardsPage = lazy(() => import("../pages/wizards/WizardsPage"));
  const AccountPage = lazy(() => import("../pages/accounts/AccountPage"));
  const WidgetsPage = lazy(() => import("../pages/widgets/WidgetsPage"));
  const ChatPage = lazy(() => import("../pages/chat/ChatPage"));
  const GolfClub = lazy(() => import("../pages/golf-club/GolfClub"));
  const Membership = lazy(() => import("../pages/membership/Membership"));
  const Banners = lazy(() => import("../pages/banner"));
  const Booking = lazy(() => import("../pages/booking/Booking"));
  const AppAdmin = lazy(() => import("../pages/app-user/AppAdmin"));
  const AppCustomer = lazy(() => import("../pages/app-user/AppCustomer"));
  const TermsConditions = lazy(
    () => import("../pages/terms-conditions/TermsConditions"),
  );
  const PrivacyPolicy = lazy(
    () => import("../pages/privacy-policy/PrivacyPolicy"),
  );
  const PaymentPrivacy = lazy(
    () => import("../pages/payment-privacy/PaymentPrivacy"),
  );
  const PaymentTermsConditions = lazy(
    () => import("../pages/payment-terms-conditions/PaymentTermsConditions"),
  );
  const AboutApp = lazy(() => import("../pages/about-app/AboutApp"));
  const GolfHoles = lazy(() => import("../pages/holes/GolfHoles"));
  const FAQ = lazy(() => import("../pages/faq/FAQ"));
  const GolfClubOffers = lazy(
    () => import("../pages/golf-offer/GolfClubOffers"),
  );
  const Company = lazy(() => import("../pages/company/Company"));
  const Contactus = lazy(() => import("../pages/contactus/Contactus"));
  const MembershipDescription = lazy(
    () => import("../pages/membership-description/MembershipDescription"),
  );

  const routes = [
    { pathname: "/auth/*", element:  <Navigate to ="/dashboard" /> },
    // { pathname: "/", element: isSuperAdmin  ? <Navigate to ="/dashboard" /> : <Navigate to ="/golf-club" /> }, // <----- Redirect to Dashboard after success login/registartion
    { pathname: "/golf-club", element: <GolfClub /> },
    { pathname: "/squezz-me", element: <SquezzMe /> },
    { pathname: "/waiting", element: <WaitingList /> },
    { pathname: "/preference", element: <Preference /> },
    { pathname: "/preference_details", element: <PreferenceDetails /> },
    { pathname: "/apparel_details", element: <ApparelDetails /> },
    {
      pathname: "/membership",
      element: isSuperAdmin ? <Membership /> : <Navigate to="/error/404" />,
    },
    {
      pathname: "/booking",
      element: isSuperAdmin ? <Booking /> : <Navigate to="/error/404" />,
    },
    {
      pathname: "/app-user/app-admin",
      element: isSuperAdmin ? <AppAdmin /> : <Navigate to="/error/404" />,
    },
    {
      pathname: "/app-user/app-customer",
      element: isSuperAdmin ? <AppCustomer /> : <Navigate to="/error/404" />,
    },
    {
      pathname: "/terms-conditions",
      element: isSuperAdmin ? (
        <TermsConditions />
      ) : (
        <Navigate to="/error/404" />
      ),
    },
    {
      pathname: "/privacy-policy",
      element: isSuperAdmin ? <PrivacyPolicy /> : <Navigate to="/error/404" />,
    },
    {
      pathname: "/payment-terms-conditions",
      element: isSuperAdmin ? (
        <PaymentTermsConditions />
      ) : (
        <Navigate to="/error/404" />
      ),
    },
    {
      pathname: "/payment-privacy",
      element: isSuperAdmin ? <PaymentPrivacy /> : <Navigate to="/error/404" />,
    },
    {
      pathname: "/about-app",
      element: isSuperAdmin ? <AboutApp /> : <Navigate to="/error/404" />,
    },
    { pathname: "/crafted/pages/profile/*", element: <ProfilePage /> },
    { pathname: "/crafted/pages/wizards/*", element: <WizardsPage /> },
    { pathname: "/crafted/widgets/*", element: <WidgetsPage /> },
    { pathname: "crafted/account/*", element: <AccountPage /> },
    // { pathname: "apps/chat/*", element: <ChatPage /> },
    { pathname: "*", element: <Navigate to="/error/404" /> }, // <----- Redirect to Page not found if no route match
    { pathname: "/golf-holes", element: <GolfHoles /> },
    {
      pathname: "/faq",
      element: isSuperAdmin ? <FAQ /> : <Navigate to="/error/404" />,
    },
    { pathname: "/golf-offers", element: <GolfClubOffers /> },
    {
      pathname: "/banner",
      element: isSuperAdmin ? <Banners /> : <Navigate to="/error/404" />,
    },
    {
      pathname: "/company",
      element: isSuperAdmin ? <Company /> : <Navigate to="/error/404" />,
    },
    {
      pathname: "/booking",
      element: isSuperAdmin ? <Booking /> : <Navigate to="/error/404" />,
    },
    {
      pathname: "/golfAdmin",
      element:  <GolfAdmin/> ,
    },
    {
      pathname: "/dashboard",
      element:  <DashboardPage />,
    },
    {
      pathname: "/subscription-info",
      element: isSuperAdmin ? (
        <SubscriptionInfo />
      ) : (
        <Navigate to="/error/404" />
      ),
    },
    {
      pathname: "/coupon",
      element: isSuperAdmin ? <Coupon /> : <Navigate to="/error/404" />,
    },
    {
      pathname: "/contactus",
      element: isSuperAdmin ? <Contactus /> : <Navigate to="/error/404" />,
    },
    {
      pathname: "/membership-description",
      element: isSuperAdmin ? (
        <MembershipDescription />
      ) : (
        <Navigate to="/error/404" />
      ),
    },
  ];

  return (
    <Routes>
      <Route element={<MasterLayout />}>
        {routes.map((route) => (
          <Route
            key={route.pathname}
            path={route.pathname}
            element={<SuspensedView>{route.element}</SuspensedView>}
          />
        ))}
      </Route>
    </Routes>
  );
};

const SuspensedView: FC<WithChildren> = ({ children }) => {
  const baseColor = getCSSVariableValue("--bs-primary");
  TopBarProgress.config({
    barColors: {
      "0": baseColor,
    },
    barThickness: 1,
    shadowBlur: 5,
  });
  return <Suspense fallback={<TopBarProgress />}>{children}</Suspense>;
};

export { PrivateRoutes };
