import { useFormik } from "formik";
import { Button, Modal } from "react-bootstrap";
import { createPortal } from "react-dom";
import { useMutation } from "react-query";
import * as Yup from "yup";
import { KTIcon } from "../../_metronic/helpers";
import { addEditApparel } from "../../api";
import { snackActions } from "../../helpers/SnackUtilsConfigurator";

type Props = {
  show: boolean;
  handleClose: (isSubmit: boolean) => void;
  editData: {
    _id: string;
    value: string;
    type: string;
    catType: string;
  };
};

const modalsRoot = document.getElementById("root-modals") || document.body;

const AddEditApparel = ({ show, handleClose, editData }: Props) => {
  const { mutateAsync: addEditData, isLoading: isAddingEditing } = useMutation(
    "add-edit",
    addEditApparel,
  );

  const validationSchema = Yup.object().shape({
    value: Yup.string()
      .trim() // Remove leading and trailing whitespace
      .required("name is required and should not be blank"),
    catType: Yup.string().required("Category Type selection is required"),
    // .test(
    //   "no-blank-spaces",
    //   "Blank spaces are not allowed",
    //   (value) => !/\s/.test(value),
    // ),
  });

  const formik = useFormik({
    initialValues: {
      value: editData?.value
        ? editData?.value?.replace(/^\s+|\s+$/g, "")
        : "",
      catType: editData?.catType ?? "",
    },
    validationSchema,
    onSubmit: async (values) => {
      let bodyParams = {
        _id: editData?._id ? editData?._id : undefined,
        value: values?.value
          ? values?.value?.replace(/^\s+|\s+$/g, "")
          : "",
        type: editData.type,
        catType: values?.catType
      };
      let response = await addEditData(bodyParams);
      if (response?.status) {
        snackActions.success(response?.message ?? "Added/Edited Successfully!");
        formik.resetForm();
        handleClose(true);
      } else {
        snackActions.error(response?.message ?? "Something went wrong!");
      }
    },
  });

  return createPortal(
    <Modal
      id="kt_modal_create_app"
      tabIndex={-1}
      aria-hidden="true"
      dialogClassName="modal-dialog modal-dialog-centered mw-600px"
      show={show}
      onHide={() => handleClose(false)}
      backdrop="static"
    >
      <div className="modal-header">
        <h2>{editData ? 'Edit' : 'Add'} Apparel</h2>
        {/* begin::Close */}
        <div
          className="btn btn-sm btn-icon btn-active-color-primary"
          onClick={() => handleClose(false)}
        >
          <KTIcon className="fs-1" iconName="cross" />
        </div>
        {/* end::Close */}
      </div>

      <div className="p-6">
        <form onSubmit={formik.handleSubmit} noValidate className="form">
          <div>
            <label className="form-label fw-bold">Category</label>
            <select
              className="form-select form-select-solid form-select-lg"
              {...formik.getFieldProps("catType")}
            >
              <option value="">Select Category</option>
              <option value="Brand">Brand</option>
              <option value="Size">Size</option>
            </select>
            {formik.touched.catType && formik.errors.catType && (
              <div className="fv-plugins-message-container">
                <div className="fv-help-block">{formik.errors.catType}</div>
              </div>
            )}
          </div>
          <div className="pt-6">
            <label className="form-label fw-bold">Value</label>
            <input
              type="text"
              className="form-control form-control-lg form-control-solid mb-3 mb-lg-0"
              placeholder="Value"
              aria-label="Value"
              {...formik.getFieldProps("value")}
            />
            {formik.touched.value && formik.errors.value && (
              <div className="fv-plugins-message-container">
                <div className="fv-help-block">{formik.errors.value}</div>
              </div>
            )}
          </div>
          {/* <div className="w-100 d-flex p-5 align-items-center"> */}
          <div className="w-100 d-flex align-items-center justify-content-end p-5">
            <Button
              onClick={() => {
                formik.resetForm();
                handleClose(false);
              }}
              size="lg"
              variant="secondary"
            >
              Cancel
            </Button>
            <div className="w-15px"></div>
            <Button type="submit" size="lg" variant="primary">
              {isAddingEditing ? "Loading..." : "Submit"}
            </Button>
          </div>
        </form>
      </div>
    </Modal>,
    modalsRoot,
  );
};

export default AddEditApparel;
