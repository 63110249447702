import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import DeleteSweepIcon from "@mui/icons-material/DeleteSweep";
import DriveFileRenameOutlineIcon from "@mui/icons-material/DriveFileRenameOutline";
import { Box, Tooltip } from "@mui/material";
import { GridColDef } from "@mui/x-data-grid";
import React, { useEffect, useRef, useState } from "react";
import { Card } from "react-bootstrap";
import { useMutation } from "react-query";
import { useLocation, useNavigate } from 'react-router-dom';
import { KTCard } from "../../_metronic/helpers";
import ConfirmEventPopup from "../../_metronic/layout/components/common/ConfirmEventPopup";
import {
  deleteInterest,
  getInterestList,
} from "../../api";
import AddEditPreference from "../../components/preference/AddEditPreference";
import MuiTable from "../../components/table/MuiTable";
import { snackActions } from "../../helpers/SnackUtilsConfigurator";


  const PreferenceDetails: React.FC = () => {
  const location: any = useLocation();
  const navigate = useNavigate();
  const info = location?.state;
  const tableRef = useRef();
  const [tableOptions, setTableOptions] = useState({
    type: info.catKey,
  });
  const [showAddEditModal, setShowAddEditModal] = useState(false);
  const [showDeleteConfirmModal, setShowDeleteConfirmModal] = useState(false);
  const [selectedRow, setSelectedRow] = useState<any>();
  useEffect(() => {
    getInterest(tableOptions);
    setSelectedRow({"type" : info.catKey})
  }, []);

  const getInterest = async (
    query:
      | {
          type: string;
        }
      | undefined,
  ) => {
    let response = await getPreferenceData(query);
    if (response?.status) {}
  };

  const {
    mutateAsync: getPreferenceData,
    data: preferenceData,
    isLoading: isGettingPreferenceData,
  } = useMutation("preference-list", getInterestList);

  const handleModalClose = (isSubmit: boolean) => {
    setShowAddEditModal(false);
    setSelectedRow({"type" : info.catKey})
    if (isSubmit) {
      let query = {
        type: tableOptions?.type ?? "",
      };
      getInterest(query);
    }
  };

  const handleCloseDelete = () => {
    setShowDeleteConfirmModal(false);
    setSelectedRow({"type" : info.catKey});
  };

  const { mutateAsync: deleteInterestData, isLoading: isDeleting } = useMutation(
    "delete",
    deleteInterest,
  );

  const handleDeleteConfirm = async () => {
    let response = await deleteInterestData({ id: selectedRow?._id });
    if (response?.status) {
      snackActions.success(response?.message);
      handleCloseDelete();
      let query = {
        type: tableOptions?.type ?? "",
      };
      getInterest(query);
    } else {
      snackActions.error(response?.message);
    }
  };

  const columns: GridColDef[] = [
    {
      field: "name",
      headerName: "Name",
      flex: 1.2,
      sortable: false,
      headerAlign: "center",
      align: "center",
      renderCell: (data) => (
        <span>
        {data?.row?.name ?? "N/A"}
      </span>
      ),
    },
    {
      field: "actions",
      headerName: "Actions",
      flex: 1,
      sortable: false,
      headerAlign: "center",
      align: "center",
      renderCell: (data) => (
        <Box display="flex" alignItems="center">
          <Tooltip title="Edit">
            <DriveFileRenameOutlineIcon
              onClick={() => {
                setSelectedRow(data.row);
                setShowAddEditModal(true);
              }}
              sx={{ fontSize: 22, cursor: "pointer" }}
              color="warning"
            />
          </Tooltip>
          <Tooltip title="Delete">
            <DeleteSweepIcon
              onClick={() => {
                setSelectedRow(data.row);
                setShowDeleteConfirmModal(true);
              }}
              sx={{ fontSize: 22, ml: 1.7, cursor: "pointer" }}
              color= "error"
            />
          </Tooltip>
        </Box>
      ),
    },
  ];

  return (
    <Card className="p-6">
         {/* <PageTitle>{info.title}</PageTitle> */}
         <>
            <Box className="top-title d-md-flex align-items-center mt-md-6 top-0">
              <span onClick={() => navigate("/preference")} className="btn btn-secondary me-md-6 mb-5 mb-md-0">
                <ArrowBackIcon /> Back
              </span>
              <h5 className="fs-3 page-heading">{info.title}</h5>
            </Box>
         <KTCard>
          <MuiTable
            addLabel='Add'
            isAddEnable={true}
            columns={columns}
            data= {preferenceData?.data ?? []}
            tableRef={tableRef}
            loading={isGettingPreferenceData}
            handleAddClick={() => setShowAddEditModal(true)}
            hideFooterPagination={true}
          />
        </KTCard>
        {showAddEditModal && (
          <AddEditPreference
            editData={selectedRow}
            show={showAddEditModal}
            handleClose={handleModalClose}
          />
        )}
        {showDeleteConfirmModal && (
          <ConfirmEventPopup
            showPopup={showDeleteConfirmModal}
            handleClose={handleCloseDelete}
            handleConfirmed={handleDeleteConfirm}
            loading={isDeleting}
            title={"Delete"}
            message={"Are you sure, you want to delete?"}
          />
        )}
        </>
    </Card>
  );
};

export default PreferenceDetails;