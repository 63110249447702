import { format } from "date-fns";

const formatDate = (
  date: string | undefined | null,
  formatString?: string,
  addTimeZoneOffset = false,
  shouldConvertToCST = false,
) => {
  if (!date) return "";
  try {
    const dt = new Date(date);
    if (shouldConvertToCST) {
      let usaTime = dt.toLocaleString("en-US", {
        timeZone: "CST",
      });
      return format(
        new Date(usaTime),
        formatString ? formatString : "MMM dd yyyy",
      );
    }
    const dtDateOnly = new Date(
      addTimeZoneOffset
        ? dt.valueOf() + dt.getTimezoneOffset() * 60 * 1000
        : dt,
    );
    return format(dtDateOnly, formatString ? formatString : "MMM dd yyyy");
  } catch (e) {
    console.log(e);
    return "";
  }
};


const formatCurrency = (
  amount: number = 0,
  currency?: string,
  isIntegerOnly?: boolean,
) => {
  amount = isNaN(amount) ? 0 : amount;
  var formatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: currency ? currency : "USD",
    minimumFractionDigits: isIntegerOnly ? 0 : 2,
  });
  return formatter.format(amount);
};

 const formatDateTimeString = (
  dateString: string
) => {
  const date = new Date(dateString);
  const options: Intl.DateTimeFormatOptions = {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
    hour: '2-digit',
    minute: '2-digit',
    second: '2-digit',
    hour12: false,
    timeZone: 'UTC' // Specify the source time zone as UTC
};

return date.toLocaleString('en-US', options);
}


export { formatCurrency, formatDate, formatDateTimeString };
