/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable jsx-a11y/anchor-is-valid */
import clsx from "clsx";
import { useFormik } from "formik";
import { useEffect, useState } from "react";
import { useMutation } from "react-query";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import * as Yup from "yup";
import { PasswordMeterComponent } from "../../_metronic/assets/ts/components";
import { adminContactUs } from "../../api";
import { snackActions } from "../../helpers/SnackUtilsConfigurator";
import { useAuth } from "../../pages/auth/core/Auth";
// import { registerUser } from "../../store/slice/auth/authActions";

const initialValues = {
  fullname: "",
  email: "",
  description: "",
  title: "",
};

const registrationSchema = Yup.object().shape({
  title: Yup.string()
  .trim()
    .required("Title is required"),
  fullname: Yup.string()
  .trim()
    .required("Name is required"),
  email: Yup.string()
    .email("Wrong email format")
    .required("Email is required"),
  description: Yup.string().trim().required("Description is required")
});

export function ContactUs() {
  const [loading, setLoading] = useState(false);
  const { saveAuth, setCurrentUser } = useAuth();
  const dispatch = useDispatch();

  const { mutateAsync: contactUsAPI, isLoading: isSending } = useMutation(
    "add-edit",
    adminContactUs,
  );
  const formik = useFormik({
    initialValues,
    validationSchema: registrationSchema,
    onSubmit: async (values, { setStatus, setSubmitting }) => {
      const payload = {
        name: values?.fullname ?? "",
        email: values?.email ?? "",
        title: values?.title ?? "",
        description: values?.description ?? ""
      }
      let response = await contactUsAPI(payload);
      if (response?.status) {
        snackActions.success(
          response?.message ?? "Added/Edited Successfully!",
        );
        formik.resetForm();
      } else {
        snackActions.error(response?.message ?? "Something went wrong!");
      }
      // setLoading(true);
      // try {
      //   const payload = {
      //     fullname: values?.fullname,
      //     email: values?.email,
      //     description: values?.description ?? "",
      //     title: values?.title ?? "",
      //   };
      // } catch (error) {
      //   console.error(error);
      //   saveAuth(undefined);
      //   setStatus("The registration details is incorrect");
      //   setSubmitting(false);
      //   setLoading(false);
      // }
    },
  });

  useEffect(() => {
    PasswordMeterComponent.bootstrap();
  }, []);

  return (
    <form
      className="form w-100 fv-plugins-bootstrap5 fv-plugins-framework"
      noValidate
      id="kt_login_signup_form"
      onSubmit={formik.handleSubmit}
    >
      {/* begin::Heading */}
      <div className="text-center mb-8">
        {/* begin::Title */}
        <h1 className="text-dark fw-bolder mb-3">Contact Us</h1>
        {/* end::Title */}
      </div>
      {/* end::Heading */}

      {formik.status && (
        <div className="mb-lg-15 alert alert-danger">
          <div className="alert-text font-weight-bold">{formik.status}</div>
        </div>
      )}

      {/* begin::Form group Name */}
      <div className="fv-row mb-8">
        <label className="form-label fw-bolder text-dark fs-6">Title</label>
        <input
          placeholder="Title"
          type="text"
          autoComplete="off"
          {...formik.getFieldProps("title")}
          className={clsx(
            "form-control bg-transparent",
            {
              "is-invalid": formik.touched.title && formik.errors.title,
            },
            {
              "is-valid": formik.touched.title && !formik.errors.title,
            },
          )}
        />
        {formik.touched.title && formik.errors.title && (
          <div className="fv-plugins-message-container">
            <div className="fv-help-block">
              <span role="alert">{formik.errors.title}</span>
            </div>
          </div>
        )}
      </div>
      <div className="fv-row mb-8">
        <label className="form-label fw-bolder text-dark fs-6">Full Name</label>
        <input
          placeholder="Full Name"
          type="text"
          autoComplete="off"
          {...formik.getFieldProps("fullname")}
          className={clsx(
            "form-control bg-transparent",
            {
              "is-invalid": formik.touched.fullname && formik.errors.fullname,
            },
            {
              "is-valid": formik.touched.fullname && !formik.errors.fullname,
            },
          )}
        />
        {formik.touched.fullname && formik.errors.fullname && (
          <div className="fv-plugins-message-container">
            <div className="fv-help-block">
              <span role="alert">{formik.errors.fullname}</span>
            </div>
          </div>
        )}
      </div>
      {/* end::Form group */}

      {/* begin::Form group Email */}
      <div className="fv-row mb-8">
        <label className="form-label fw-bolder text-dark fs-6">Email</label>
        <input
          placeholder="Email"
          type="email"
          autoComplete="off"
          {...formik.getFieldProps("email")}
          className={clsx(
            "form-control bg-transparent",
            { "is-invalid": formik.touched.email && formik.errors.email },
            {
              "is-valid": formik.touched.email && !formik.errors.email,
            },
          )}
        />
        {formik.touched.email && formik.errors.email && (
          <div className="fv-plugins-message-container">
            <div className="fv-help-block">
              <span role="alert">{formik.errors.email}</span>
            </div>
          </div>
        )}
      </div>
      {/* end::Form group */}

      {/* begin::Form group Message */}
      <div className="fv-row mb-8">
        <label className="form-label fw-bolder text-dark fs-6">Description</label>
        <textarea
          placeholder="Description"
          multiple
          {...formik.getFieldProps("description")}
          className={clsx(
            "form-control bg-transparent",
            {
              "is-invalid": formik.touched.description && formik.errors.description,
            },
            {
              "is-valid": formik.touched.description && !formik.errors.description,
            },
          )}
          cols={4}
          rows={4}
        />
        {formik.touched.description && formik.errors.description && (
          <div className="fv-plugins-message-container">
            <div className="fv-help-block">
              <span role="alert">{formik.errors.description}</span>
            </div>
          </div>
        )}
      </div>

      {/* begin::Form group */}
      <div className="text-center">
        <button
          type="submit"
          id="kt_sign_up_submit"
          className="btn btn-lg btn-primary w-100 mb-5"
          disabled={formik.isSubmitting || !formik.isValid}
        >
          {!loading && <span className="indicator-label">Submit</span>}
          {loading && (
            <span className="indicator-progress" style={{ display: "block" }}>
              Please wait...{" "}
              <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
            </span>
          )}
        </button>
        <Link to="/auth/login">
          <button
            type="button"
            id="kt_login_signup_form_cancel_button"
            className="btn btn-lg btn-light-primary w-100 mb-5"
          >
            Cancel
          </button>
        </Link>
      </div>
      {/* end::Form group */}
    </form>
  );
}
