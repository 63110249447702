/* eslint-disable react-hooks/exhaustive-deps */
import { left } from "@popperjs/core";
import clsx from "clsx";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { IInitialState } from "../../../../constants/types";
import { toAbsoluteUrl } from "../../../helpers";
import { useLayout } from "../../core";
import { Header } from "./Header";
import { Navbar } from "./Navbar";

export function HeaderWrapper() {
  const state: IInitialState = useSelector((state: IInitialState) => state);

  const { config, classes } = useLayout();

  if (!config.app?.header?.display) {
    return null;
  }

  return (
    <div id="kt_app_header" className="app-header">
      <div
        id="kt_app_header_container"
        className={clsx(
          "app-container flex-lg-grow-1 w-100",
          classes.headerContainer.join(" "),
          config.app?.header?.default?.containerClass,
        )}
      >
        {config.app.sidebar?.display && (
          <>
            <div
              className="app-sidebar-toggle-ico d-flex align-items-center d-lg-none ms-n2 pe-2 w-75"
              title="Show sidebar menu"
            >
              <div
                className="btn btn-icon btn-active-color-primary w-25px h-25px me-auto"
                id="kt_app_sidebar_mobile_toggle"
              >
                <svg version="1.1" x="0px" y="0px" viewBox="0 0 24 24">
                  <g>
                    <path d="M3,11h14c0.6,0,1-0.4,1-1s-0.4-1-1-1H3c-0.6,0-1,0.4-1,1S2.4,11,3,11z" />
                    <path d="M3,7h18c0.6,0,1-0.4,1-1s-0.4-1-1-1H3C2.4,5,2,5.4,2,6S2.4,7,3,7z" />
                    <path d="M21,13H3c-0.6,0-1,0.4-1,1s0.4,1,1,1h18c0.6,0,1-0.4,1-1S21.6,13,21,13z" />
                    <path d="M17,17H3c-0.6,0-1,0.4-1,1s0.4,1,1,1h14c0.6,0,1-0.4,1-1S17.6,17,17,17z" />
                  </g>
                </svg>
              </div>
              <div className="d-flex align-items-center flex-grow-1 flex-lg-grow-0 ms-5">
                <Link to="/dashboard" className="d-lg-none">
                  <img
                    alt="Logo"
                    src={toAbsoluteUrl("/media/app-logo/logo-color.png")}
                    className="h-35px"
                  />
                </Link>
              </div>
            </div>
          </>
        )}

        {!(
          config.layoutType === "dark-sidebar" ||
          config.layoutType === "light-sidebar"
        ) && (
          <div className="d-flex align-items-center flex-grow-1 flex-lg-grow-0 me-lg-15">
            <Link to="/dashboard">
              {config.layoutType !== "dark-header" ? (
                <img
                  alt="Logo"
                  src={toAbsoluteUrl("/media/logos/default.svg")}
                  className="h-20px h-lg-30px app-sidebar-logo-default"
                />
              ) : (
                <>
                  <img
                    alt="Logo"
                    src={toAbsoluteUrl("/media/logos/default-dark.svg")}
                    className="h-20px h-lg-30px app-sidebar-logo-default theme-light-show"
                  />
                  <img
                    alt="Logo"
                    src={toAbsoluteUrl("/media/logos/default-small-dark.svg")}
                    className="h-20px h-lg-30px app-sidebar-logo-default theme-dark-show"
                  />
                </>
              )}
            </Link>
          </div>
        )}

        <div
          id="kt_app_header_wrapper"
          className="d-flex align-items-center justify-content-end flex-lg-grow-1 w-25"
        >
          <label
            className="fs-3 page-heading"
            style={{
              marginLeft: 50,
              marginTop: 0,
              textAlign: left,
              width: "100%",
            }}
          >
            {state?.header?.title}
          </label>
          {/* <div className="notification-ico">
            <span className="count">4</span>
            <figure>
              <svg version="1.1" x="0px" y="0px" viewBox="0 0 20 20">
                <g>
                  <path d="M2.5,18.2c-0.2,0-0.4-0.1-0.5-0.2c-0.2-0.2-0.3-0.5-0.2-0.8l1.5-4.5c-0.5-1-0.7-2.1-0.7-3.2c0-1.5,0.4-2.9,1.2-4.1c0.8-1.2,1.9-2.2,3.2-2.9C8,2,9.2,1.8,10.4,1.8h0.4c2,0.1,3.8,0.9,5.1,2.3c1.4,1.4,2.2,3.2,2.3,5.1l0,0.5c0,1.2-0.3,2.4-0.8,3.5c-0.6,1.3-1.6,2.4-2.9,3.2c-1.2,0.8-2.7,1.2-4.1,1.2c0,0,0,0,0,0c-1.1,0-2.2-0.2-3.2-0.7l-4.5,1.5C2.7,18.2,2.6,18.2,2.5,18.2zM10.4,3.2c-1,0-1.9,0.2-2.8,0.7C6.5,4.4,5.6,5.3,5,6.3c-0.6,1-0.9,2.2-0.9,3.3c0,1,0.2,2,0.7,2.8c0.1,0.2,0.1,0.4,0,0.6l-1.1,3.3L7,15.2c0.2-0.1,0.4,0,0.6,0c0.9,0.4,1.9,0.7,2.8,0.7c1.2,0,2.3-0.3,3.3-0.9c1-0.6,1.8-1.5,2.3-2.6c0.4-0.9,0.7-1.9,0.7-2.8c0,0,0,0,0,0V9.2c-0.1-1.5-0.7-3-1.8-4.1c-1.1-1.1-2.6-1.8-4.1-1.8L10.4,3.2C10.4,3.2,10.4,3.2,10.4,3.2z" />
                </g>
              </svg>
            </figure>
          </div> */}
          {/* <div className="notification-ico">
            <figure>
              <svg version="1.1" x="0px" y="0px" viewBox="0 0 20 20">
                <g>
                  <path d="M17.9,13.5c0,0-2.2-1.6-2.2-6.9c0-1.5-0.6-3-1.7-4.1C13,1.5,11.5,0.9,10,0.9S7,1.5,5.9,2.6S4.2,5.1,4.2,6.7c0,5.3-2.2,6.9-2.2,6.9c-0.3,0.2-0.4,0.5-0.3,0.8c0.1,0.3,0.4,0.5,0.7,0.5h15c0.3,0,0.6-0.2,0.7-0.5C18.3,14.1,18.2,13.7,17.9,13.5z M4.1,13.4c0.8-1.2,1.6-3.3,1.6-6.8c0-1.1,0.4-2.2,1.2-3s1.9-1.2,3-1.2c1.1,0,2.2,0.4,3,1.2c0.8,0.8,1.2,1.9,1.2,3c0,3.4,0.8,5.5,1.6,6.8H4.1z" />
                  <path d="M11.8,16.9c-0.4-0.2-0.8-0.1-1,0.3c-0.1,0.1-0.2,0.3-0.3,0.3c-0.3,0.2-0.6,0.2-0.9,0c-0.1-0.1-0.3-0.2-0.3-0.3c-0.2-0.4-0.7-0.5-1-0.3c-0.4,0.2-0.5,0.7-0.3,1c0.2,0.4,0.5,0.7,0.9,0.9c0.4,0.2,0.8,0.3,1.2,0.3c0.4,0,0.8-0.1,1.2-0.3c0.4-0.2,0.7-0.5,0.9-0.9C12.3,17.5,12.2,17.1,11.8,16.9z" />
                </g>
              </svg>
            </figure>
          </div> */}

          {config.app.header.default?.content === "menu" &&
            config.app.header.default.menu?.display && (
              <div
                className="app-header-menu app-header-mobile-drawer align-items-stretch"
                data-kt-drawer="true"
                data-kt-drawer-name="app-header-menu"
                data-kt-drawer-activate="{default: true, lg: false}"
                data-kt-drawer-overlay="true"
                data-kt-drawer-width="225px"
                data-kt-drawer-direction="end"
                data-kt-drawer-toggle="#kt_app_header_menu_toggle"
                data-kt-swapper="true"
                data-kt-swapper-mode="{default: 'append', lg: 'prepend'}"
                data-kt-swapper-parent="{default: '#kt_app_body', lg: '#kt_app_header_wrapper'}"
              >
                <Header />
              </div>
            )}
          <Navbar />
        </div>
      </div>
    </div>
  );
}
