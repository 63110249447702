import clsx from "clsx";
import { useUserInfo } from "../../../../hooks/useUserInfo";
import { HeaderUserMenu } from "../../../partials";
import { useLayout } from "../../core";

const itemClass = "ms-1 ms-lg-3";
// const btnClass =
//   "btn btn-icon btn-custom btn-icon-muted btn-active-light btn-active-color-primary w-35px h-35px w-md-40px h-md-40px";
const userAvatarClass = "symbol-35px symbol-md-40px";
const btnIconClass = "fs-1";

const Navbar = () => {
  const { config } = useLayout();
  const userInfo = useUserInfo();

  const { firstName: userFirstName, lastName: userLastName } = userInfo;

  const firstLetterFirstName = userFirstName[0];
  const firstLetterLastName = userLastName[0];

  return (
    <div className="app-navbar flex-shrink-0">
      {/* <div className={clsx("app-navbar-item align-items-stretch", itemClass)}>
        <Search />
      </div> */}

      {/* <div className={clsx("app-navbar-item", itemClass)}>
        <div id="kt_activities_toggle" className={btnClass}>
          <KTIcon iconName="chart-simple" className={btnIconClass} />
        </div>
      </div> */}

      {/* <div className={clsx("app-navbar-item", itemClass)}>
        <div
          data-kt-menu-trigger="{default: 'click'}"
          data-kt-menu-attach="parent"
          data-kt-menu-placement="bottom-end"
          className={btnClass}
        >
          <KTIcon iconName="element-plus" className={btnIconClass} />
        </div>
        <HeaderNotificationsMenu />
      </div> */}

      {/* <div className={clsx("app-navbar-item", itemClass)}>
        <div
          className={clsx("position-relative", btnClass)}
          id="kt_drawer_chat_toggle"
        >
          <KTIcon iconName="message-text-2" className={btnIconClass} />
          <span className="bullet bullet-dot bg-success h-6px w-6px position-absolute translate-middle top-0 start-50 animation-blink" />
        </div>
      </div> */}

      {/* <div className={clsx("app-navbar-item", itemClass)}>
        <ThemeModeSwitcher
          toggleBtnClass={clsx("btn-active-light-primary btn-custom")}
        />
      </div> */}

      <div className={clsx("app-navbar-item", itemClass)}>
        <div
          className={clsx("cursor-pointer symbol", userAvatarClass)}
          data-kt-menu-trigger="{default: 'click'}"
          data-kt-menu-attach="parent"
          data-kt-menu-placement="bottom-end"
        >
          {/* <img src={toAbsoluteUrl("/media/avatars/blank.png")} alt="" /> */}
          <div style={avatarInitials}>
            <span style={{ color: "#fff" }}>{firstLetterFirstName}</span>
            <span style={{ color: "#fff" }}>{firstLetterLastName}</span>
          </div>
        </div>
        <HeaderUserMenu />
      </div>
    </div>
  );
};

export const avatarInitials = {
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  width: "45px",
  height: "45px",
  borderRadius: "50%",
  backgroundColor: "#555AAC",
  fontSize: "18px",
};

export { Navbar };
