
import { DatePicker } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider/LocalizationProvider';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import dayjs from 'dayjs';
import { useFormik } from "formik";
import { useEffect, useState } from "react";
import { Button, Col, Modal, Row } from "react-bootstrap";
import { createPortal } from "react-dom";
import { useMutation } from 'react-query';
import "react-quill/dist/quill.snow.css";
import { KTIcon } from "../../_metronic/helpers";
import { getAllGolfClub, getDashboard } from '../../api';

type Props = {
  show: boolean;
  details : any;
  isClose : boolean;
  handleClose: (isSubmit: boolean, obj: any, isClose: boolean) => void;
};

const modalsRoot = document.getElementById("root-modals") || document.body;

const FilterDashboard =  ({ show, handleClose ,details }: Props) => {

    const [selectedStartDate, setSelectedStartDate] = useState<dayjs.Dayjs | null>(null);
    const [selectedEndDate, setSelectedEndDate] = useState<dayjs.Dayjs | null>(null);
    const [selectedStartTime, setSelectedStartTime] = useState<Date | null>(null);
    const [selectedEndTime, setSelectedEndTime] = useState<Date | null>(null);
    const [selectedGolfValue, setSelectedGolfValue] = useState('');
    const [selectedGolfName, setSelectedGolfName] = useState('');
    const [selectedPlayer, setSelectedPlayer] = useState('');

    const [selectedStartTimeValue, setSelectedStartTimeValue] = useState('');
    const [selectedEndTimeValue, setSelectedEndTimeValue] = useState('');
    const [isValid, setValidationValue] = useState(true);

    const [tableOptions, setTableOptions] = useState({
      offset: 0,
      search: "",
      limit : 100,
    });

  const handleStartDateChange = (e: any) => {
    setSelectedStartDate(e);
  };

  const formatDate = (date: dayjs.Dayjs | null) => {
    if(date && date.isValid()){
      const dd = dayjs(date).format("MM-DD-YYYY");
      return dd;
    }
    return '';
  };

  const handleEndDateChange = (e: any) => {
    setSelectedEndDate(e);
  };

  const handleStartTimeChange = (newValue: any) => {
    setSelectedStartTime(newValue);
   const dateString = formatTime(new Date(newValue.$d));
   setSelectedStartTimeValue(dateString);
  };

  const handleReset = () => {
    setSelectedStartDate(null);
    setSelectedEndDate(null);
    setSelectedStartTime(null);
    setSelectedEndTime(null);
    setSelectedEndTimeValue('');
    setSelectedStartTimeValue('');
    setSelectedPlayer('');
    setSelectedGolfValue('');
    handleClose(false,{},true);

  };

  function formatTime(date : any) {
    const hours = date.getHours();
    const minutes = date.getMinutes();
    const ampm = hours >= 12 ? 'PM' : 'AM';
    const formattedHours = hours % 12 === 0 ? 12 : hours % 12;
    const addHours = formattedHours < 10 ? '0' + formattedHours : formattedHours;
    const formattedMinutes = minutes < 10 ? '0' + minutes : minutes;
    return `${addHours}:${formattedMinutes} ${ampm}`;
  }

  const handleEndTimeChange = (newValue: any) => {
    setSelectedEndTime(newValue);

    const dateString = formatTime(new Date(newValue.$d));
    setSelectedEndTimeValue(dateString);
   
};

  const {
    mutateAsync: getGolfData,
    data: golfData,
    isLoading: isGettingGolfData,
  } = useMutation("golf-list", getAllGolfClub);

  useEffect(() => {
    getGolf(tableOptions);
  }, []);

  useEffect(() => {
    if (details ) {
      setSelectedStartDate(dayjs(details?.selectedStartDate));
      setSelectedEndDate(dayjs(details?.selectedEndDate));
    
      setSelectedPlayer(details?.selectedPlayer);
      setSelectedStartTime(details?.selectedStartTimeFormat);
      setSelectedEndTime(details?.selectedEndTimeFormat);
      setSelectedStartTimeValue(details?.selectedStartTime);
      setSelectedEndTimeValue(details?.selectedEndTime);
      setSelectedGolfValue(details?.selectedGolfId);

      let golfName  =  selectedGolfValue ? 
               golfData?.data?.docs.find((c: any) => c?._id === selectedGolfValue) 
          : 
            null;

            let golf = golfName ? golfName?.name : "Select Golf Club";

          setSelectedGolfName(golf);
    
     
    }
  }, [details]);


  useEffect(() => {
    if (selectedStartTime && selectedEndTime) {
      if (selectedEndTime <= selectedStartTime) {
        setValidationValue(false);
        return;
      } else {
        setValidationValue(true);
        return;
      }
    }

    if(selectedStartDate && selectedEndDate){
      if (selectedEndDate < selectedStartDate) {
        setValidationValue(false);
        return;
      } else {
        setValidationValue(true);
        return;
      }
    }
  }, [selectedStartTime, selectedEndTime ,selectedStartDate, selectedEndDate]);

  
  const getGolf = async (
    query:
      | {
          offset: number | string;
          search: string;
        }
      | undefined,
  ) => {
    let response = await getGolfData(query);
    if (response?.status) {
      setTableOptions((prevState: any) => {
        return {
          ...prevState,
          offset: response?.data?.offset,
          limit: response?.data?.limit,
          hasNextPage: response?.data?.hasNextPage,
          hasPrevPage: response?.data?.hasPrevPage,
          totalRowCount: response?.data?.totalDocs,
        };
      });
    }
  };

      const {
        mutateAsync: getDashboardData,
        data: dashboardData,
        isLoading: isApply,
      } = useMutation("get-dashboard", getDashboard);

    const formik: any = useFormik({
      
        initialValues: {
          
        },
        onSubmit: async () => {
           if(!isValid){
              return;
           }

            let golfName  =  selectedGolfValue ? 
               golfData?.data?.docs.find((c: any) => c?._id === selectedGolfValue) 
          : 
            null;

            let obj = {
              selectedStartDate : formatDate(selectedStartDate),
              selectedEndDate :  formatDate(selectedEndDate),
              selectedStartTimeFormat : selectedStartTime,
              selectedStartTime : selectedStartTimeValue,
              selectedEndTime : selectedEndTimeValue,
              selectedEndTimeFormat : selectedEndTime,
              selectedGolfValue : selectedGolfValue,
              selectedPlayer : selectedPlayer,
              selectedGolfName : golfName?.name,
            }
            handleClose(true,obj,false);
          },
      });

  

  return createPortal(
    <Modal
      id="kt_modal_create_app"
      tabIndex={-1}
      aria-hidden="true"
      dialogClassName="modal-dialog modal-dialog-centered mw-700px scroll"
      show={show}
      onHide={() => handleClose(false, {},false)}
      backdrop="static"
    >
      <div className="modal-header">
        <h2>Filter</h2>
        {/* begin::Close */}
        <div
          className="btn btn-sm btn-icon btn-active-color-primary"
          onClick={() => handleClose(false,{},false)}>
          <KTIcon className="fs-1" iconName="cross" />
        </div>
        {/* end::Close */}
      </div>

      <div className="p-6">
        <form onSubmit={formik.handleSubmit} noValidate className="form">
          <div>
          <Row>
              <Col xs={6}>
                <div className="form-group">
                  <label className="form-label fw-bold">Start Date</label>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  name="startDate"
                  onChange={handleStartDateChange}
                  slotProps={{
                    textField: {
                      size: "small",
                      error: false,
                    },
                  }}
                  value={selectedStartDate}
                  views={["year", "month", "day"]}
                  sx={{
                    "&.MuiFormControl-root": {
                      width: "100%",
                    },
                  }}
                />
                
              </LocalizationProvider>
              </div>
                
              </Col>
              <Col xs={6}>
              <div className="form-group">
                  <label className="form-label fw-bold">End Date</label>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  name="endDate"
                  onChange={handleEndDateChange}
                  slotProps={{
                    textField: {
                      size: "small",
                      error: false,
                    },
                  }}
                  value={selectedEndDate}
                  views={["year", "month", "day"]}
                  sx={{
                    "&.MuiFormControl-root": {
                      width: "100%",
                    },
                  }}
                />
                 {selectedStartDate && selectedEndDate && (
                <div style={{ color: 'red' }}>
                    {selectedEndDate < selectedStartDate && (
                        <p>End date must be after start date.</p>
                    )}
                </div>
            )}
              </LocalizationProvider>
              </div>
              </Col>
            </Row>
            
            <Row style={{marginTop : '15px'}}>
              <Col xs={6}>
                <div>
                    <label className="form-label fw-bold">Start Time</label>
                    <LocalizationProvider
                    dateAdapter={AdapterDayjs}>
                <TimePicker
                 ampm={true} 
                  onChange={handleStartTimeChange}
                  value={selectedStartTime} 
                  sx={{
                    width: "100%",
                    "& .MuiOutlinedInput-input": { py: 1.35 },
                  }}
                /> 
              </LocalizationProvider>
                </div>
             
              </Col>
              <Col xs={6}>
              <div>
                    <label className="form-label fw-bold">End Time</label>
                    <LocalizationProvider
                    dateAdapter={AdapterDayjs}>
                <TimePicker
                 onChange={handleEndTimeChange}
                  value={selectedEndTime}
                  sx={{
                    width: "100%",
                    "& .MuiOutlinedInput-input": { py: 1.35 },
                  }}
                />
                {selectedStartTime && selectedEndTime && (
                <div style={{ color: 'red' }}>
                    {selectedEndTime <= selectedStartTime && (
                        <p>End time must be after start time.</p>
                    )}
                </div>
            )}
                 {/* {formik.touched.endTime && formik.errors.endTime && (
                  <div className="fv-plugins-message-container">
                    <div className="fv-help-block">
                      {formik.errors.endTime}
                    </div>
                  </div>
                )} */}
              </LocalizationProvider>
                </div>
              </Col>
            </Row>

            <Row style={{marginTop : '15px'}}>
            <Col xs={6}>

                <div className="form-group">
                  <label className="form-label fw-bold">Golf Club</label>
                  <select
                    className="form-select form-select-solid form-select-lg"
                    value={selectedGolfValue}
                    onChange={(e) => setSelectedGolfValue(e.target.value)}
                  >
                    <option value="">{selectedGolfName} </option>
                    {golfData?.data
                    ? golfData?.data?.docs.map((golf: any) => (
                      <option value={golf?._id} key={golf?._id}>
                      {golf?.name}
                    </option>
                      ))
                    : null}
                 
                  </select>
                </div>
            </Col>
              <Col xs={6}>
                <div>
                  <label className="form-label fw-bold">Player</label>
                  <select
                    className="form-select form-select-solid form-select-lg"
                    value={selectedPlayer}
                    onChange={(e) => setSelectedPlayer(e.target.value)}
                  >
                    <option value="">Select Player</option>
                    <option value="1">1</option>
                    <option value="2">2</option>
                    <option value="3">3</option>
                    <option value="4">4</option>
                  </select>
                </div>
              </Col>
            </Row>
          </div>
            
           
          <div className="w-100 d-flex align-items-center justify-content-end mt-5">
            <Button
              onClick={() => {
                formik.resetForm();
                handleReset()
               // handleClose(false,{});
              }}
              size="lg"
              variant="secondary"
            >
              Reset
            </Button>
            <div className="w-15px"></div>
            <Button type="submit" size="lg" variant="primary">
              
              {isApply ? "Loading..." : "Apply"}
            </Button>
          </div>
        </form>
      </div>
    </Modal>,
    modalsRoot,
  );
};


export default FilterDashboard;
