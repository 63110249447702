/* eslint-disable jsx-a11y/anchor-is-valid */

import { ListGroup } from "react-bootstrap";


interface Dropdown4Props {
  handleTimeClick : any
  selectedValue : any,
  }

// const CategoryCardView : React.FC<CategoryCardViewProps> = ({category,onClick, handleAddCategoryClick ,handleDeleteCategoryClick }) => {
const Dropdown4:  React.FC<Dropdown4Props> = ({handleTimeClick,selectedValue}) => {
  const handleClick = (item : any) => {
    if (handleTimeClick) {
      handleTimeClick(item); 
    }
  };

  const lstTimeRange = [
    { name: '1 Minute', value: 1},
    { name: '5 Minutes', value:5 },
    { name: '10 Minutes', value: 10},
    { name: '15  Minutes', value:15 },
    { name: '30 Minutes',value: 30 },
    { name: '1 Hour',value: 60 },
    { name: '2 Hours',value: 120 },
  ];


  return (
    <div className="menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg-light-primary fw-bold w-200px pb-3" data-kt-menu="true">
      <div className="menu-item px-2">
        <div className="menu-content fs-6 text-dark fw-bolder px-3 py-4">
          Select Time
        </div>
      </div>
      <div className="separator mb-2 opacity-75"></div>

      <ListGroup>
          <ListGroup.Item style={{ backgroundColor: 'transparent', border: 'none' }}>
            {lstTimeRange?.map((value: any) => (
              <div className="menu-item">
                <label className="menu-link px-2 py-1"
                  style={{ fontWeight: 'normal', fontSize: '14px', cursor: 'pointer', paddingBottom: '0', margin:'0' ,color : selectedValue == value.name ? 'orange' : 'black' }}
                  onClick={() => handleClick(value)}>
                  {value.name}
                </label>
              </div>
            ))}
          </ListGroup.Item>
        </ListGroup>
    </div>
  );
};

export { Dropdown4 };
