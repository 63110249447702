import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import DeleteSweepIcon from "@mui/icons-material/DeleteSweep";
import DriveFileRenameOutlineIcon from "@mui/icons-material/DriveFileRenameOutline";
import ManageAccountsIcon from "@mui/icons-material/ManageAccounts";
import { Box, Grid, Tooltip } from "@mui/material";
import { GridColDef } from "@mui/x-data-grid";
import React, { useEffect, useRef, useState } from "react";
import { Button, Card } from "react-bootstrap";
import { useMutation } from "react-query";
import { useNavigate } from "react-router-dom";
import { KTCard, KTIcon } from "../../_metronic/helpers";
import ConfirmEventPopup from "../../_metronic/layout/components/common/ConfirmEventPopup";
import CustomTooltip from "../../_metronic/layout/components/common/CustomTooltip";
import {
  deleteGolfClub,
  getAdminList,
  getAllGolfClub,
  suspendActiveAdmin
} from "../../api";
import AddDeleteGolfAdmin from "../../components/app-user/AddDeleteGolfAdmin";
import MuiTable from "../../components/table/MuiTable";
import { charValidate } from "../../components/utility";
import { snackActions } from "../../helpers/SnackUtilsConfigurator";
import useDebounce from "../../hooks/useDebounce";

const GolfAdmin: React.FC = () => {
  const [showAddEditModal, setShowAddEditModal] = useState(false);
  const [showDeleteConfirmModal, setShowDeleteConfirmModal] = useState(false);
  const [showActiveDeActivePopup, setShowActiveDeActivePopup] = useState(false);

  const [isDeleteGolfAdmin, setDeleteGolfAdmin] = useState(false);
  const [selectedRow, setSelectedRow] = useState<any>();
  const [tableOptions, setTableOptions] = useState({
    offset: 0,
    limit: 10,
    hasNextPage: true,
    hasPrevPage: true,
    totalRowCount: undefined,
    search: "",
    golfId: "",
    roleId: 3,
  });
  const debounceSearchValue: string = useDebounce(tableOptions?.search, 500);
  let defaultQuery = {
    offset: 0,
    limit: 10,
    search: "",
    roleId: 3,
  };
  const tableRef = useRef();
  const navigate = useNavigate();

  const {
    mutateAsync: getAdminData,
    data: appCustomerListData,
    isLoading: isGettingGolfData,
  } = useMutation("golf-list", getAdminList);

  const { mutateAsync: deleteGolfData, isLoading: isDeletingGolf } =
    useMutation("delete-golf", deleteGolfClub);

  const { mutateAsync: suspendActive, isLoading: isSuspending } = useMutation(
    "API",
    suspendActiveAdmin,
  );


  const {
    mutateAsync: getGolfData,
    data: golfData,
    isLoading: isGettingAdminGolfData,
  } = useMutation("golf-list", getAllGolfClub);


  const getGolf = async (
    query:
      | {
          offset: number | string;
          search: string;
          golfId: string;
        }
      | undefined,
  ) => {
    let response = await getGolfData(query);
    if (response?.status) {
      setTableOptions((prevState: any) => {
        return {
          ...prevState,
          offset: response?.data?.offset,
          limit: response?.data?.limit,
          hasNextPage: response?.data?.hasNextPage,
          hasPrevPage: response?.data?.hasPrevPage,
          totalRowCount: response?.data?.totalDocs,
        };
      });
    }
  };


  useEffect(() => {
    getCustomer(tableOptions);
    getGolf(tableOptions);
  }, []);

  useEffect(() => {
    getCustomer({
      ...tableOptions,
      search: debounceSearchValue ?? "",
    });
  }, [tableOptions?.golfId,debounceSearchValue]);

  const getCustomer = async (
    query:
      | {
          offset: number | string;
          limit: number;
          search: string;
        }
      | undefined,
  ) => {
    let response = await getAdminData(query);
    if (response?.status) {
      setTableOptions((prevState: any) => {
        return {
          ...prevState,
          offset: response?.data?.offset,
          limit: response?.data?.limit,
          hasNextPage: response?.data?.hasNextPage,
          hasPrevPage: response?.data?.hasPrevPage,
          totalRowCount: response?.data?.totalDocs,
        };
      });
    }
  };

  const columns: GridColDef[] = [
    {
      field: "firstName",
      headerName: "First Name",
      flex: 1,
      minWidth: 120,
      sortable: false,
      renderCell: (data: any) => data?.row?.firstName ?? "N/A",
    },
    {
      field: "lastName",
      headerName: "Last Name",
      flex: 1,
      minWidth: 120,
      sortable: false,
      renderCell: (data: any) => data?.row?.lastName ?? "N/A",
    },
    {
      field: "email",
      headerName: "Email",
      flex: 1,
      minWidth: 160,
      sortable: false,
      renderCell: (data) => (
        <CustomTooltip
          title={
            data?.row?.email ? (
              <h6 style={{ color: "white" }}>{data?.row?.email}</h6>
            ) : (
              "N/A"
            )
          }
        >
          <span>
            {data?.row?.email ? charValidate(data?.row?.email, 20) : "N/A"}
          </span>
        </CustomTooltip>
      ),
    },
    {
      field: "mobileNumber",
      headerName: "Mobile Number",
      flex: 1,
      minWidth: 120,
      sortable: false,
      // renderCell: (data: any) => data?.row?.mobileNumber ?? "N/A",
      renderCell: (data: any) =>  (
        <>
         {`${data?.row?.countryCode} ${data?.row?.mobileNumber}` ?? "N/A"}
        </>
      ) 
    },
    {
      field: "isUserActive",
      headerName: "Status",
      flex: 1,
      minWidth: 120,
      sortable: false,
      renderCell: (data: any) =>
        typeof data?.row?.isUserActive !== "undefined" ? (
          data?.row?.isUserActive === 1 ? (
            <div className="d-flex align-items-center">
              <CheckCircleIcon sx={{ fontSize: 15, mr: 1, color: "green" }} />
              <span>Active</span>
            </div>
          ) : (
            <div className="d-flex align-items-center">
              <CheckCircleIcon sx={{ fontSize: 15, mr: 1 }} color="disabled" />
              <span>Suspended</span>
            </div>
          )
        ) : (
          "N/A"
        ),
    },
    {
      field: "actions",
      headerName: "Actions",
      // flex: 1,
      minWidth: 120,
      sortable: false,
      headerAlign: "center",
      align: "center",
      renderCell: (data) => (
        <Box display="flex" alignItems="center">
          <Tooltip title="Make Admin User Active / Suspend">
            <ManageAccountsIcon
              onClick={() => {
                setSelectedRow(data.row);
                setShowActiveDeActivePopup(true);
              }}
              sx={{ fontSize: 22, mr: 1.7, cursor: "pointer" }}
              color="primary"
            />
          </Tooltip>
          <Tooltip title="Edit">
            <DriveFileRenameOutlineIcon
              onClick={() => {
                setSelectedRow(data.row);
                setShowAddEditModal(true);
                setDeleteGolfAdmin(false);
              }}
              sx={{ fontSize: 22, cursor: "pointer" }}
              color="primary"
            />
          </Tooltip>
          <Tooltip title="Delete">
            <DeleteSweepIcon
              onClick={() => {
                setSelectedRow(data.row);
                setShowAddEditModal(true);
                setDeleteGolfAdmin(true);
                // setSelectedRow(data.row);
                // setShowDeleteConfirmModal(true);
              }}
              sx={{ fontSize: 22, ml: 1.7, cursor: "pointer" }}
              color="error"
            />
          </Tooltip>
        </Box>
      ),
    },
  ];

  const handleModalClose = (isSubmit: boolean) => {
    setShowAddEditModal(false);
    handleCloseActivePopup();
    setSelectedRow("");
    if (isSubmit) {
      let query = {
        offset: tableOptions?.offset ?? 10,
        limit: tableOptions?.limit ?? 10,
        roleId: 3,
        golfId: tableOptions?.golfId ?? "",
        search: debounceSearchValue ?? "",
      };
      getCustomer(query);
    }
  };

  const handleCloseDelete = () => {
    setShowDeleteConfirmModal(false);
  };

  const handleCloseActivePopup = () => {
    setShowActiveDeActivePopup(false);
  };

  const deleteGolf = async () => {
    let response = await deleteGolfData({ id: selectedRow?._id });
    if (response?.status) {
      snackActions.success(response?.message);
      handleCloseDelete();
      let query = {
        offset: tableOptions?.offset ?? 10,
        limit: tableOptions?.limit ?? 10,
        roleId: 3,
        golfId : tableOptions?.golfId ?? "",
        search: debounceSearchValue ?? "",
      };
      getCustomer(query);
    } else {
      snackActions.error(response?.message);
    }
  };

  const handlePagination = (values: { page: number; pageSize: number }) => {
    let page = values?.page;
    let pageSize = values?.pageSize;
    let query = {
      offset:
        pageSize == tableOptions?.limit ? (page === 0 ? 0 : `${page}` + 0) : 0,
      limit: pageSize ?? 10,
      roleId: 3,
      golfId: tableOptions?.golfId ?? "",
      search: debounceSearchValue ?? "",
    };
    getCustomer(query);
  };

  const handleChangeFilters = (fieldName: string, value: string) => {
    setTableOptions((prevState: any) => {
      return {
        ...prevState,
        [fieldName]: value,
      };
    });
  };

  const activeDeactive = async () => {
    let bodyParams = {
      userId: selectedRow?._id ?? "",
      flag: selectedRow?.isUserActive === 1 ? true : false,
    };

    let response = await suspendActive(bodyParams);
    if (response?.status) {
      snackActions.success(response?.message ?? "Edited Successfully!");
      handleModalClose(true);
    } else {
      snackActions.error(response?.message ?? "Something went wrong!");
    }
  };

  return (
    <Card className="p-6">
      {/* <PageTitle>App Admin</PageTitle> */}
      <>
        <KTCard>
          <MuiTable
            columns={columns}
            data={appCustomerListData?.data?.docs ?? []}
            tableRef={tableRef}
            loading={isGettingGolfData}
            isRowClickable={true}
            handlePagination={handlePagination}
            isAddEnable={false}
            tableOptions={tableOptions}
            filters={
                <Grid item md={4} xs={12}>
                        <Box pb={3} display="flex" alignItems="center">
                    <Box pr={3}>
                    <label
                      className="form-label"
                      style={{ marginLeft: 5, fontWeight: "900", fontSize:"15.6px"}} >
                        Golf Course
                    </label>
                    <select
                      onChange={(e: any) =>
                        handleChangeFilters("golfId", e?.target?.value)
                      }
                      value={tableOptions?.golfId}
                      className="form-select form-select-solid form-select-lg"
                    //   value={tableOptions?.type}
                    >
                      <option value="">All</option>
                      {golfData?.data
                    ? golfData?.data?.docs.map((golf: any) => (
                      <option value={golf?._id} key={golf?._id}>
                      {golf?.name}
                    </option>
                      ))
                    : null}
                      {/* <option value="9Holes">9 Holes</option>
                      <option value="18Holes">18 Holes</option> */}
                    </select>
                  </Box>
                <Box pr={3} className="top-search">
                  <label
                    className="form-label"
                    style={{ marginLeft: 5, fontWeight: "900", fontSize:"15.6px"  }}
                  >
                    Search By Name
                  </label>
                  <KTIcon iconName="magnifier" className="fs-1" />
                  <input
                    type="text"
                    className="form-control form-control-lg form-control-solid"
                    placeholder="Search by name"
                    aria-label="Search"
                    onChange={(e: any) =>
                      handleChangeFilters("search", e?.target?.value)
                    }
                    value={tableOptions?.search}
                  />
                </Box>
                <div className="ms-auto" style={{ marginTop: 31 }}>
              <Button 
                className="d-flex align-items-center"
                onClick={() => {
                  setShowAddEditModal(true);
                  setDeleteGolfAdmin(false);
                }}>
                <KTIcon iconName="plus" className="fs-1" />
                Add Admin
              </Button>
              </div>
              </Box>
                </Grid>
              
            }
          />
        </KTCard>
        {showAddEditModal && (
          <AddDeleteGolfAdmin
            editData={selectedRow}
            show={showAddEditModal}
            isDelete = {isDeleteGolfAdmin}
            handleClose={handleModalClose}
          />
        )}
        {showDeleteConfirmModal && (
          <ConfirmEventPopup
            showPopup={showDeleteConfirmModal}
            handleClose={handleCloseDelete}
            handleConfirmed={deleteGolf}
            loading={isDeletingGolf}
            title={"Delete User"}
            message={"Are you sure, want to delete this user?"}
          />
        )}
        {showActiveDeActivePopup && (
          <ConfirmEventPopup
            showPopup={showActiveDeActivePopup}
            handleClose={handleCloseActivePopup}
            handleConfirmed={activeDeactive}
            loading={isSuspending}
            title={"Active / Suspend Admin"}
            message={`Are you sure, want to ${
              selectedRow?.isUserActive === 1 ? '"Suspend"' : '"Activate"'
            } this admin?`}
          />
        )}
      </>
    </Card>
  );
};

export default GolfAdmin;
